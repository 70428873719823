<template>
  <div id="app">
    <!-- 内容 -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app"
};
</script>

<style lang="scss">
html,
body,
div,
p,
span,
h1,
h2,
h3,
h4,
h5,
ul,
li,
input,
textarea,
u,
select,
a:focus {
  padding: 0;
  margin: 0;
  list-style: none;
  outline: none;
}

img[lazy="loading"] {
  display: block;
}

input {
  -webkit-user-select: auto; /*webkit浏览器*/
}

textarea {
  -webkit-user-select: auto; /*webkit浏览器*/
}

#app {
  background-color: #fff;
}

body {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #e6e6e6;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-track {
    background-color: #fff;
    box-shadow: none;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.06);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  }
}

// 导航栏二级样式
.nav_two .el-menu--popup-bottom-start {
  margin-top: 0 !important;
}

.nav_two .el-menu--popup {
  padding: 0 !important;
  min-width: 112px !important;
  border-radius: 0 0 8px 8px !important;
}

.nav_two .el-menu .el-menu-item,
.nav_two .el-menu .el-submenu__title {
  width: 112px !important;
  height: 40px !important;
  line-height: 40px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #282d3c !important;
  text-align: center !important;
}

.nav_two .el-menu .is-active {
  background-color: #eaf1fe !important;
  color: #3076fd !important;
}

.nav_two .el-menu li:last-child {
  border-radius: 0 0 8px 8px !important;
}

// 预览图片层级
.el-image-viewer__wrapper {
  z-index: 1000000 !important;
}

// 提示层级
.el-message {
  z-index: 1000000 !important;
}
</style>