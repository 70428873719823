/**
 * 常规方法定义
 * 1、不能用()=>{}  箭头函数定义
 * 2、大量计算方法建议放computed
 */
import { post } from '../../../http/http'

export default {
    getData () {
        var nowurl = location.href
        post(this.$api.public.getJssdkSign, { nowurl }).then(res => {
            if (res.code == 200) {
                wx.config({
                    // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    debug: false,
                    // 必填，公众号的唯一标识
                    appId: res.data.appid,
                    // 必填，生成签名的时间戳
                    timestamp: "" + res.data.timestamp,
                    // 必填，生成签名的随机串
                    nonceStr: res.data.noncestr,
                    // 必填，签名
                    signature: res.data.signature,
                    // 必填，需要使用的JS接口列表，所有JS接口列表
                    jsApiList: ['onMenuShareAppMessage', 'updateTimelineShareData']
                })
                wx.error(function (res) {
                    this.$message.error("出错了：" + JSON.stringify(res)) //这个地方的好处就是wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
                })
                wx.ready(function () {
                    wx.onMenuShareAppMessage({
                        title: "东北过敏联盟成员单位申请表",
                        desc: "",
                        link: location.href,
                        imgUrl: "https://xcx.hzszyjyl.com/upload/default/szyj-logo.png",
                        // type: 'link', // 分享类型,music、video或link，不填默认为link
                        dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
                        success: function () {
                            // 用户确认分享后执行的回调函数
                        }
                    })
                    //分享到朋友圈
                    wx.updateTimelineShareData({
                        title: "东北过敏联盟成员单位申请表",
                        link: location.href,
                        imgUrl: "https://xcx.hzszyjyl.com/upload/default/szyj-logo.png",
                        success: function () {
                            // 用户确认分享后执行的回调函数
                        }
                    })
                })
                return false
            } else {
                this.$message.error(res.msg)
            }
        }).catch(err => {
            this.$message.error(err.msg)
        })
    },
    // 选择省市
    onFinish ({ selectedOptions }) {
        this.ruleForm.province = selectedOptions[0].text
        this.ruleForm.city = selectedOptions[1].text
        this.cascaderValue = selectedOptions.map((option) => option.text).join('/')
        this.region = false
    },
    // 提交
    submit () {
        if (this.ruleForm.realname == '') {
            this.$message.warning('请输入姓名')
            return
        }

        if (!/^[\u4E00-\u9FA5]{2,8}$/.test(this.ruleForm.realname)) {
            this.$message.warning('请输入中文姓名')
            return
        }

        if (this.ruleForm.province == '' || this.ruleForm.city == '') {
            this.$message.warning('请选择省市')
            return
        }

        if (this.ruleForm.hospital == '') {
            this.$message.warning('请输入医院')
            return
        }

        if (this.ruleForm.postitle == '') {
            this.$message.warning('请输入职称')
            return
        }

        if (this.ruleForm.posjob == '') {
            this.$message.warning('请输入职务')
            return
        }

        if (this.ruleForm.section == '') {
            this.$message.warning('请输入科室')
            return
        }

        if (!/^1[3456789]\d{9}$/.test(this.ruleForm.mobile)) {
            this.$message.warning('请输入正确的手机号')
            return
        }

        this.realname = this.ruleForm.realname
        this.gender = this.ruleForm.gender == '男' ? '先生' : '女士'

        post(this.$api.public.saveYlbData, { jsondata: JSON.stringify(this.ruleForm) }).then(res => {
            if (res.code == 200) {
                this.$message.success('填报成功')
                this.isResult = true
                localStorage.removeItem('ruleFormWrite')
            } else {
                this.$message.error(res.msg)
            }
        }).catch(err => {
            this.$message.error(err.msg)
        })
    }
}