/**
 * api, 对应后台接口请求地址
 */
const api = 'https://xcx.hzszyjyl.com'

export default {
    // 首页
    homePage: {
        getData: `${api}/web/index/getIndexData` // 首页信息
    },
    // 公司介绍
    introducePage: {
        getData: `${api}/web/Company/getIndexData` // 介绍信息
    },
    // 产品中心
    productPage: {
        getData: `${api}/web/Product/getIndexData` // 产品信息
    },
    // 新闻资讯
    newsPage: {
        getData: `${api}/web/News/getNewsList`, // 列表信息
        getDetail: `${api}/web/News/getNewsDetail` // 资讯详情
    },
    // 公共接口
    public: {
        saveFreeData: `${api}/web/index/saveFreeData`, // 免费咨询
        saveFormData: `${api}/web/index/saveFormData`, // 填报
        getJssdkSign: `${api}/web/Wechat/getJssdkSign`,
        saveYlbData: `${api}/web/Index/saveYlbData`, // 填报
        getChatIds: `${api}/web/imgtext/getChatIds` // 获取web
    }
}