<template>
  <div class="main">
    <!-- 顶部导航 -->
    <Nav></Nav>

    <!-- 客服 -->
    <div
      class="main_customer_service"
      @click="$store.commit('isShowChatStatus', true)"
    >
      <img src="../assets/customer_service.png" alt="" />
    </div>

    <!-- 聊天 -->
    <Chat @close="$store.commit('isShowChatStatus', false)"></Chat>

    <!-- 内容 -->
    <slot name="main_content"></slot>

    <!-- 底部 -->
    <Base></Base>
  </div>
</template>

<script>
import Nav from "./Nav.vue"
import Base from "./Base.vue"
import Chat from "./Chats.vue"

export default {
  name: "Main",
  components: {
    Nav,
    Base,
    Chat
  }
};
</script>

<style lang="scss" scoped>
@import "../common.scss";
@function px2vw($px) {
  @return $px / 750 * 100 + vw;
}
// 客服
.main_customer_service {
  width: 80px;
  height: 80px;
  position: fixed;
  top: 500px;
  right: 20px;
  z-index: 99999;
  cursor: pointer;
  img {
    width: 80px;
    height: 80px;
    @include respond-to("phone") {
      width: px2vw(110);
      height: px2vw(110);
    }
  }
  @include respond-to("pad") {
    top: 300px;
    right: 29px;
  }
  @include respond-to("phone") {
    width: px2vw(110);
    height: px2vw(110);
    top: px2vw(500);
    right: px2vw(24);
  }
}
</style>
