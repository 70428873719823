<template>
  <Main>
    <div class="sensitive" slot="main_content">
      <img
        v-if="!isMobile"
        class="sensitive_img"
        src="../../assets/sensitive_bg.jpg"
        alt=""
      />
      <img
        v-else
        class="sensitive_img"
        src="../../assets/phone/sensitive_bg.jpg"
        alt=""
      />
      <!-- 过敏机理 -->
      <div class="sensitive_cont">
        <div class="sensitive_cont_title">
          <div class="sensitive_cont_title_txt">
            <div>过敏机理</div>
          </div>
          <div class="sensitive_cont_title_tip">
            <img
              src="../../assets/blue_point.png"
              alt=""
              class="blue_pointer"
            />
          </div>
        </div>
        <div class="sensitive_cont_txt">
          <img src="../../assets/allergic_mechanism.png" alt="" />
          <div class="sensitive_cont_txt_right">
            <p>
              正常的情况下，当外来物质进入人体后大都面临两种命运，如果被机体识别为有用或无害物质，则这些物质将与人体和谐相处，最终将被吸收、利用或被自然排出。
            </p>
            <p>
              如这些物质被识别为有害物质时，机体的免疫系统则立即做出反应，将其驱除或消灭，这就是免疫应答发挥的保护作用。免疫应答是人的防卫体系重要的功能之一，但是如果这种应答超出了正常范围，即免疫系统对无害物质进行攻击时，这种情况称为变态反应。
            </p>
            <p>
              变态反应是一种疾病，因为无端的攻击也会损害正常的身体组织，甚至免疫系统居然有时对机体本身的组织进行攻击和破坏，对人体的健康非常不利。
            </p>
          </div>
        </div>
      </div>
      <!-- 过敏预防 -->
      <div class="prevention_cont">
        <img
          class="right_top_abs_bg"
          src="../../assets/right_top_pointer.png"
          alt=""
        />
        <div class="sensitive_cont_title">
          <div class="sensitive_cont_title_txt">
            <div>过敏预防</div>
          </div>
          <div class="sensitive_cont_title_tip">
            <img
              src="../../assets/blue_point.png"
              alt=""
              class="blue_pointer"
            />
          </div>
        </div>
        <div class="prevention_container">
          <div class="box">
            <img src="../../assets/prevention1.png" alt="" />
            <div>
              <div class="box_title">识别过敏源</div>
              <div class="box_sub_title">
                通过过敏测试等方式，了解个体过敏原，针对性地采取预防措施。
              </div>
            </div>
          </div>
          <div class="box">
            <img src="../../assets/prevention2.png" alt="" />
            <div>
              <div class="box_title">环境清洁</div>
              <div class="box_sub_title">
                保持室内环境清洁，定期换洗衣物和床上用品，减少尘螨和霉菌的滋生。可辅以空气净化器、除湿器。
              </div>
            </div>
          </div>
          <div class="box">
            <img src="../../assets/prevention3.png" alt="" />
            <div>
              <div class="box_title">合理饮食</div>
              <div class="box_sub_title">
                避免含有过敏原的食物。在饮食方面，均衡饮食、多摄入富含抗过敏营养素的食物，如维生素C、Omega-3脂肪酸等，有助于增强免疫系统。
              </div>
            </div>
          </div>
          <div class="box">
            <img src="../../assets/prevention4.png" alt="" />
            <div>
              <div class="box_title">皮肤管理</div>
              <div class="box_sub_title">
                皮肤比较敏感的人群，尽量不要经常更换护肤品；使用无香料、无刺激成分的洗护用品；做好皮肤保湿及防晒工作。
              </div>
            </div>
          </div>
          <div class="box">
            <img src="../../assets/prevention5.png" alt="" />
            <div>
              <div class="box_title">减少宠物接触</div>
              <div class="box_sub_title">
                减少与宠物的直接接触，保持宠物居住区域的清洁，定期洗澡和刷毛等措施有助于减少过敏反应。
              </div>
            </div>
          </div>
          <div class="box">
            <img src="../../assets/prevention6.png" alt="" />
            <div>
              <div class="box_title">良好的生活环境</div>
              <div class="box_sub_title">
                保持充足的睡眠（7-9小时），尽量避免吸烟，限制饮酒量；适当地放松身心（如冥想、瑜伽等）；每周保持较为稳定的运动量。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Main>
</template>

<script>
import computed from "./src/computed"
import methods from "./src/methods"
import watch from "./src/watch"
import Main from "../../components/Main"

export default {
  name: "sensitive",
  data () {
    return {
      activeIndex: "/sensitive?id=1",
      objData: "",
      isMobile: false,
    }
  },
  components: {
    Main,
  },
  watch,
  methods,
  computed,
  created () {
    window.addEventListener("resize", this.isMobi)
    this.isMobi()
  },
};
</script>

<style lang="scss" scoped>
@import "./css/index.scss";
</style>
