<template>
  <Main>
    <div class="patient" slot="main_content">
      <img
        v-if="!isMobile"
        class="product_img"
        src="../../assets/banner2.jpg"
        alt=""
      />
      <img
        v-else
        class="product_img"
        src="../../assets/phone/banner2.jpg"
        alt=""
      />

      <!-- 产品介绍 -->
      <div class="patient_introduce">
        <div class="patient_title">
          <div>产品介绍</div>
          <img src="../../assets/blue_point.png" alt="" />
        </div>
        <div class="patient_introduce_list">
          <div class="patient_introduce_item" ref="patient1">
            <img
              v-if="!isMobile"
              class="patient_introduce_item_img patient_introduce_item_right patient_introduce_item_img1"
              src="../../assets/patient1.png"
              alt=""
            />
            <div class="patient_introduce_item_cont">
              <div class="patient_introduce_title">
                咨询服务：一对一病情咨询，高效诊疗
              </div>
              <div class="patient_introduce_desc">
                与医生一对一实时在线沟通，进行精准化诊疗，诊疗过程医生全程监控，根据病情变化及时调整诊疗方案，随时随地接收医嘱信息。同时，医生门诊信息展示，满足患者线上线下相结合的问诊需求。
              </div>
              <div
                class="patient_introduce_btn"
                @click="$store.commit('isShowChatStatus', true)"
              >
                了解详情
              </div>
            </div>
            <img
              v-if="isMobile"
              class="patient_introduce_item_img patient_introduce_item_right patient_introduce_item_img1"
              src="../../assets/patient1.png"
              alt=""
            />
          </div>
          <div class="patient_introduce_item" ref="patient2">
            <div class="patient_introduce_item_cont">
              <div class="patient_introduce_title">
                量表评估：过敏性疾病全病程评估
              </div>
              <div class="patient_introduce_desc">
                量表评估完全数字化、曲线化，院内、院外全病程评估患者病情变化，并根据实际评估结果对患者当前病情给出辅导建议及日常生活建议，同时根据多次评估结果形成评估曲线，便于患者以及绑定医生及时发现病情问题、调整治疗方案。
              </div>
              <div
                class="patient_introduce_btn"
                @click="$store.commit('isShowChatStatus', true)"
              >
                了解详情
              </div>
            </div>
            <div
              class="patient_introduce_item_img patient_introduce_item_left patient_introduce_item_img2 patient_introduce_item_inventory"
              id="patient"
            >
              <div class="patient_introduce_item_inventory_circle">
                <div class="dashed_circle"></div>
                <div class="patient_introduce_item_inventory_circle1">
                  <div class="patient_introduce_item_inventory_circle2">
                    <div class="patient_introduce_item_inventory_circle3">
                      开始评测
                    </div>
                  </div>
                </div>
              </div>
              <div class="patient_introduce_item_inventory_list">
                <div
                  class="patient_introduce_item_inventory_txt patient_introduce_item_inventory_txt1"
                >
                  <img src="../../assets/eva_icon1.png" alt="" />
                  <div>GMTZZJ</div>
                </div>
                <div
                  class="patient_introduce_item_inventory_txt patient_introduce_item_inventory_txt2"
                >
                  <img src="../../assets/eva_icon3.png" alt="" />
                  <div>ACT</div>
                </div>
                <div
                  class="patient_introduce_item_inventory_txt patient_introduce_item_inventory_txt3"
                >
                  <img src="../../assets/eva_icon4.png" alt="" />
                  <div>AMZSC</div>
                </div>
                <div
                  class="patient_introduce_item_inventory_txt patient_introduce_item_inventory_txt4"
                >
                  <img src="../../assets/eva_icon5.png" alt="" />
                  <div>TNSS</div>
                </div>
                <div
                  class="patient_introduce_item_inventory_txt patient_introduce_item_inventory_txt5"
                >
                  <img src="../../assets/eva_icon2.png" alt="" />
                  <div>药物过敏</div>
                </div>
                <div
                  class="patient_introduce_item_inventory_txt patient_introduce_item_inventory_txt6"
                >
                  <img src="../../assets/more2.png" alt="" />
                  <div>更多</div>
                </div>
                <img
                  class="patient_introduce_item_inventory_txt7"
                  src="../../assets/eva_bg_img.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="patient_introduce_item" ref="patient3">
            <img
              v-if="!isMobile"
              class="patient_introduce_item_img patient_introduce_item_right patient_introduce_item_img3"
              src="../../assets/patient2.png"
              alt=""
            />
            <div class="patient_introduce_item_cont">
              <div class="patient_introduce_title">
                脱敏管理：过敏诊疗全流程管理
              </div>
              <div class="patient_introduce_desc">
                通过诊前病情评估、复诊、注意事项提醒，诊中留院观察记录即刻反应，通过诊后智能随访、智能宣教以及相关硬件的居家监测，检测患者居家环境数据和健康指标的动态变化数据，对脱敏患者进行全病程管理，同时为医生进行复诊和数据查询提供依据。
              </div>
              <div
                class="patient_introduce_btn"
                @click="$store.commit('isShowChatStatus', true)"
              >
                了解详情
              </div>
            </div>
            <img
              v-if="isMobile"
              class="patient_introduce_item_img patient_introduce_item_right patient_introduce_item_img3"
              src="../../assets/patient2.png"
              alt=""
            />
          </div>
          <div class="patient_introduce_item" ref="patient4">
            <div class="patient_introduce_item_cont">
              <div class="patient_introduce_title">
                哮喘管理：全程精细化闭环管理
              </div>
              <div class="patient_introduce_desc">
                通过硬件设备对患者肺功能进行检测并对检测结果分区记录，结合用药记录以及ACT量表评分，定期对患者发送哮喘分析报告，便于患者和医生直观了解自身疾病的变化以及治疗效果。
              </div>
              <div
                class="patient_introduce_btn"
                @click="$store.commit('isShowChatStatus', true)"
              >
                了解详情
              </div>
            </div>
            <div
              class="patient_introduce_item_img patient_introduce_item_left patient_introduce_item_img4 patient_introduce_item_asthma"
              id="asthma_manage"
            >
              <img
                class="patient_introduce_item_asthma_img"
                src="../../assets/patient3.png"
                alt=""
              />
              <div class="patient_introduce_item_asthma_list">
                <div
                  class="patient_introduce_item_asthma_txt patient_introduce_item_asthma_txt1"
                >
                  <div class="patient_introduce_item_asthma_txt_cont">
                    哮喘日记
                  </div>
                </div>
                <div
                  class="patient_introduce_item_asthma_txt patient_introduce_item_asthma_txt2"
                >
                  <div class="patient_introduce_item_asthma_txt_cont">
                    过敏报告
                  </div>
                </div>
                <div
                  class="patient_introduce_item_asthma_txt patient_introduce_item_asthma_txt3"
                >
                  <div class="patient_introduce_item_asthma_txt_cont">
                    数据总览
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="patient_introduce_item" ref="patient5">
            <img
              v-if="!isMobile"
              class="patient_introduce_item_img patient_introduce_item_right patient_introduce_item_img5"
              src="../../assets/patient4.png"
              alt=""
            />
            <div class="patient_introduce_item_cont">
              <div class="patient_introduce_title">
                皮肤管理：硬件设备按需检测
              </div>
              <div class="patient_introduce_desc">
                通过皮肤检测设备的反馈，可以直观看出皮肤的各项参数，包括是否干燥、过敏发生率等，系统自动根据当前患者皮肤数据给出治疗方案，并通过与历史数据对比生成数据曲线，便于患者和医生及时发现问题、调整治疗方案。
              </div>
              <div
                class="patient_introduce_btn"
                @click="$store.commit('isShowChatStatus', true)"
              >
                了解详情
              </div>
            </div>
            <img
              v-if="isMobile"
              class="patient_introduce_item_img patient_introduce_item_right patient_introduce_item_img5"
              src="../../assets/patient4.png"
              alt=""
            />
          </div>
          <div class="patient_introduce_item" ref="patient6">
            <div class="patient_introduce_item_cont">
              <div class="patient_introduce_title">
                肠道评估：定制专属调理方案
              </div>
              <div class="patient_introduce_desc">
                通过非侵入性的方式对肠道进行评估、解读评估报告，形成肠道健康指数，帮助患者全面了解肠道功能和状态，筛选可能引发肠道问题的因素，制定专业的肠道调理方案，包括益生菌调理及饮食推送服务等。
              </div>
              <div
                class="patient_introduce_btn"
                @click="$store.commit('isShowChatStatus', true)"
              >
                了解详情
              </div>
            </div>
            <img
              class="patient_introduce_item_img patient_introduce_item_left patient_introduce_item_img6"
              src="../../assets/patient5.png"
              alt=""
            />
          </div>
          <div class="patient_introduce_item" ref="patient7">
            <div
              v-if="!isMobile"
              class="patient_introduce_item_img patient_introduce_item_right patient_introduce_item_img7 patient_introduce_item_constitution"
              id="china_medic_identification"
            >
              <div class="patient_introduce_item_constitution_circle"></div>
              <img
                class="patient_introduce_item_constitution_bg"
                src="../../assets/constitution_bg.png"
                alt=""
              />
              <div class="patient_introduce_item_constitution_list">
                <div
                  class="patient_introduce_item_constitution_txt patient_introduce_item_constitution_txt1"
                >
                  <div class="patient_introduce_item_constitution_txt_cont">
                    肺气虚寒
                  </div>
                </div>
                <div
                  class="patient_introduce_item_constitution_txt patient_introduce_item_constitution_txt2"
                >
                  <div class="patient_introduce_item_constitution_txt_cont">
                    肾阳不足
                  </div>
                </div>
                <div
                  class="patient_introduce_item_constitution_txt patient_introduce_item_constitution_txt3"
                >
                  <div class="patient_introduce_item_constitution_txt_cont">
                    肺经伏热
                  </div>
                </div>
                <div
                  class="patient_introduce_item_constitution_txt patient_introduce_item_constitution_txt4"
                >
                  <div class="patient_introduce_item_constitution_txt_cont">
                    脾气虚弱
                  </div>
                </div>
              </div>
            </div>
            <div class="patient_introduce_item_cont">
              <div class="patient_introduce_title">
                中医辨识：从体质上应对过敏性疾病
              </div>
              <div class="patient_introduce_desc">
                从中医的角度（肾、肺、脾）分析过敏性鼻炎的体质原因，出具个性化中医饮食调理及治疗方案，触及病源，改善体质。分析该体质的可能症状，便于自查和调理的有效进行。
              </div>
              <div
                class="patient_introduce_btn"
                @click="$store.commit('isShowChatStatus', true)"
              >
                了解详情
              </div>
            </div>
            <div
              v-if="isMobile"
              class="patient_introduce_item_img patient_introduce_item_right patient_introduce_item_img7 patient_introduce_item_constitution"
              id="china_medic_identification"
            >
              <div class="patient_introduce_item_constitution_circle"></div>
              <img
                class="patient_introduce_item_constitution_bg"
                src="../../assets/constitution_bg.png"
                alt=""
              />
              <div class="patient_introduce_item_constitution_list">
                <div
                  class="patient_introduce_item_constitution_txt patient_introduce_item_constitution_txt1"
                >
                  <div class="patient_introduce_item_constitution_txt_cont">
                    肺气虚寒
                  </div>
                </div>
                <div
                  class="patient_introduce_item_constitution_txt patient_introduce_item_constitution_txt2"
                >
                  <div class="patient_introduce_item_constitution_txt_cont">
                    肾阳不足
                  </div>
                </div>
                <div
                  class="patient_introduce_item_constitution_txt patient_introduce_item_constitution_txt3"
                >
                  <div class="patient_introduce_item_constitution_txt_cont">
                    肺经伏热
                  </div>
                </div>
                <div
                  class="patient_introduce_item_constitution_txt patient_introduce_item_constitution_txt4"
                >
                  <div class="patient_introduce_item_constitution_txt_cont">
                    脾气虚弱
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="patient_introduce_item" ref="patient8">
            <div class="patient_introduce_item_cont">
              <div class="patient_introduce_title">
                过敏科普：全国过敏领域各学科专家科普
              </div>
              <div class="patient_introduce_desc">
                通过全国过敏领域各学科专家、医生科普的视频、文章，患者在接受治疗的同时，了解和掌握关于过敏的知识和管理技巧，更好地理解过敏的原因、症状、预防方法和治疗选择，提高患者对过敏的认知和管理能力。
              </div>
              <div
                class="patient_introduce_btn"
                @click="$store.commit('isShowChatStatus', true)"
              >
                了解详情
              </div>
            </div>
            <img
              class="patient_introduce_item_img patient_introduce_item_left patient_introduce_item_img8"
              src="../../assets/patient6.png"
              alt=""
            />
          </div>
        </div>
      </div>

      <!-- 产品优势 -->
      <div class="patient_superiority">
        <div class="patient_title">
          <div>产品优势</div>
          <img src="../../assets/blue_point.png" alt="" />
        </div>
        <img
          class="patient_superiority_bg"
          src="../../assets/right_top_pointer.png"
          alt=""
        />
        <div class="patient_superiority_list">
          <div class="patient_superiority_item">
            <img
              v-if="!isMobile"
              class="patient_superiority_item_img"
              src="../../assets/superiority1.png"
              alt=""
            />
            <img
              v-else
              class="patient_superiority_item_img"
              src="../../assets/phone/superiority1.png"
              alt=""
            />
            <div class="patient_superiority_item_cont">
              <div class="patient_superiority_item_title">科学管理疾病</div>
              <div class="patient_superiority_item_desc">
                软硬件结合，实时监测和管理。行业专家制定的标准化流程，让疾病管理更科学。
              </div>
            </div>
          </div>
          <div class="patient_superiority_item">
            <img
              v-if="!isMobile"
              class="patient_superiority_item_img"
              src="../../assets/superiority2.png"
              alt=""
            />
            <img
              v-else
              class="patient_superiority_item_img"
              src="../../assets/phone/superiority2.png"
              alt=""
            />
            <div class="patient_superiority_item_cont">
              <div class="patient_superiority_item_title">精准诊疗疾病</div>
              <div class="patient_superiority_item_desc">
                根据用户的情况，提供个人化的健康数据管理和监护，为医生诊疗提供准确数据。
              </div>
            </div>
          </div>
          <div class="patient_superiority_item">
            <img
              v-if="!isMobile"
              class="patient_superiority_item_img"
              src="../../assets/superiority3.png"
              alt=""
            />
            <img
              v-else
              class="patient_superiority_item_img"
              src="../../assets/phone/superiority3.png"
              alt=""
            />
            <div class="patient_superiority_item_cont">
              <div class="patient_superiority_item_title">高效治疗疾病</div>
              <div class="patient_superiority_item_desc">
                基于用户的过敏病史和症状，通过医学专家的指导，制定相应的治疗及预防方案。
              </div>
            </div>
          </div>
          <div class="patient_superiority_item">
            <img
              v-if="!isMobile"
              class="patient_superiority_item_img"
              src="../../assets/superiority4.png"
              alt=""
            />
            <img
              v-else
              class="patient_superiority_item_img"
              src="../../assets/phone/superiority4.png"
              alt=""
            />
            <div class="patient_superiority_item_cont">
              <div class="patient_superiority_item_title">便捷过敏服务</div>
              <div class="patient_superiority_item_desc">
                提供便捷的诊疗服务，获取专业的医疗建议和治疗方案；帮助用户快速评估和了解自己的过敏情况。
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 社会价值 -->
      <div class="patient_value" v-if="!isMobile">
        <div class="patient_title">
          <div>社会价值</div>
          <img src="../../assets/blue_point.png" alt="" />
        </div>
        <div class="patient_value_cont">
          <div class="patient_value_left">
            <img
              v-if="society == 1"
              class="patient_value_left_img"
              src="../../assets/society1.png"
              alt=""
            />
            <img
              v-if="society == 2"
              class="patient_value_left_img"
              src="../../assets/society2.png"
              alt=""
            />
            <img
              v-if="society == 3"
              class="patient_value_left_img"
              src="../../assets/society3.png"
              alt=""
            />
            <div class="patient_value_left_cont">
              <div class="patient_value_left_title">
                {{
                  society == 1
                    ? "提高资源利用率"
                    : society == 2
                    ? "提升医学认知"
                    : society == 3
                    ? "辅助研究和创新"
                    : ""
                }}
              </div>
              <div class="patient_value_left_list">
                <div class="patient_value_left_item">
                  <div class="patient_value_left_item_diamond">
                    <div></div>
                    <div></div>
                  </div>
                  <div class="patient_value_left_item_desc">
                    {{
                      society == 1
                        ? "优化医疗资源的利用，减少患者的等待时间和资源的浪费；"
                        : society == 2
                        ? "促进医患之间更好的沟通和理解，加强患者对过敏症的了解；"
                        : society == 3
                        ? "系统数据库帮助研究人员深入了解过敏的发病机制、流行病学特征和治疗效果等方面的问题。"
                        : ""
                    }}
                  </div>
                </div>
                <div class="patient_value_left_item">
                  <div class="patient_value_left_item_diamond">
                    <div></div>
                    <div></div>
                  </div>
                  <div class="patient_value_left_item_desc">
                    {{
                      society == 1
                        ? "分析患者的过敏情况，为医疗机构诊疗提供参考，助力医疗资源更好地规划和分配。"
                        : society == 2
                        ? "为患者提供丰富的医学知识和健康教育信息，提高其健康意识和自我管理能力。"
                        : society == 3
                        ? "为新药研发、治疗方法改进等方面的创新提供参考和支持，推动过敏领域的科学发展。"
                        : ""
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="patient_value_right">
            <div
              :class="[
                'patient_value_right_item',
                society == 1 ? 'patient_value_right_item_active' : '',
              ]"
              @click="society = 1"
            >
              提高资源利用率
            </div>
            <div
              :class="[
                'patient_value_right_item',
                society == 2 ? 'patient_value_right_item_active' : '',
              ]"
              @click="society = 2"
            >
              提升医学认知
            </div>
            <div
              :class="[
                'patient_value_right_item',
                society == 3 ? 'patient_value_right_item_active' : '',
              ]"
              @click="society = 3"
            >
              辅助研究和创新
            </div>
          </div>
        </div>
      </div>

      <div class="patient_value" v-else>
        <div class="patient_title">
          <div>社会价值</div>
          <img src="../../assets/blue_point.png" alt="" />
        </div>
        <div class="patient_value_cont">
          <div class="patient_value_item">
            <img
              class="patient_value_left_img"
              src="../../assets/phone/society1.png"
              alt=""
            />
            <div class="patient_value_left_cont">
              <div class="patient_value_left_title">提高资源利用率</div>
              <div class="patient_value_left_list">
                <div class="patient_value_left_item">
                  <div class="patient_value_left_item_diamond">
                    <div></div>
                    <div></div>
                  </div>
                  <div class="patient_value_left_item_desc">
                    优化医疗资源的利用，减少患者的等待时间和资源的浪费；
                  </div>
                </div>
                <div class="patient_value_left_item">
                  <div class="patient_value_left_item_diamond">
                    <div></div>
                    <div></div>
                  </div>
                  <div class="patient_value_left_item_desc">
                    分析患者的过敏情况，为医疗机构诊疗提供参考，助力医疗资源更好地规划和分配。
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="patient_value_item">
            <img
              class="patient_value_left_img"
              src="../../assets/phone/society2.png"
              alt=""
            />
            <div class="patient_value_left_cont">
              <div class="patient_value_left_title">提升医学认知</div>
              <div class="patient_value_left_list">
                <div class="patient_value_left_item">
                  <div class="patient_value_left_item_diamond">
                    <div></div>
                    <div></div>
                  </div>
                  <div class="patient_value_left_item_desc">
                    促进医患之间更好的沟通和理解，加强患者对过敏症的了解；
                  </div>
                </div>
                <div class="patient_value_left_item">
                  <div class="patient_value_left_item_diamond">
                    <div></div>
                    <div></div>
                  </div>
                  <div class="patient_value_left_item_desc">
                    为患者提供丰富的医学知识和健康教育信息，提高其健康意识和自我管理能力。
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="patient_value_item">
            <img
              class="patient_value_left_img"
              src="../../assets/phone/society3.png"
              alt=""
            />
            <div class="patient_value_left_cont">
              <div class="patient_value_left_title">辅助研究和创新</div>
              <div class="patient_value_left_list">
                <div class="patient_value_left_item">
                  <div class="patient_value_left_item_diamond">
                    <div></div>
                    <div></div>
                  </div>
                  <div class="patient_value_left_item_desc">
                    系统数据库帮助研究人员深入了解过敏的发病机制、流行病学特征和治疗效果等方面的问题。
                  </div>
                </div>
                <div class="patient_value_left_item">
                  <div class="patient_value_left_item_diamond">
                    <div></div>
                    <div></div>
                  </div>
                  <div class="patient_value_left_item_desc">
                    为新药研发、治疗方法改进等方面的创新提供参考和支持，推动过敏领域的科学发展。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Main>
</template>

<script>
import computed from "./src/computed"
import watch from "./src/watch"
import Main from "../../components/Main"
import anime from "animejs"

export default {
  name: "patient",
  data () {
    return {
      society: 1,
      isMobile: false,
      id: this.$route.query.id ? this.$route.query.id : ''
    }
  },
  components: {
    Main,
  },
  watch,
  methods: {
    showAnime (typeArr) {
      let arr = []
      let _this = this
      let objType = {
        patient: "patient_introduce_item_inventory_txt",
        asthma_manage: "patient_introduce_item_asthma_txt",
        china_medic_identification: "patient_introduce_item_constitution_txt",
      }
      typeArr.forEach((el) => {
        if (!document.querySelector(`#${el}`)) return
        let obj = {
          el: `#${el}`,
          loop: false, //是否循环使用 true/false 设置false后执行执行一次后会销毁
          type: "all", //触发方式 支持up-down down-up all三种方式
          func: function () {
            anime({
              targets: `#${el}`,
              scale: [0, 1],
              opacity: [0, 1],
              delay: 0,
            })
            if (el === "patient") {
              anime({
                targets: ".patient_introduce_item_inventory_txt7",
                scale: [0, 1],
                opacity: [0, 1],
                delay: 150,
              })
            }
            document
              .querySelectorAll(`#${el} .${objType[el]}`)
              .forEach((item, index) => {
                anime({
                  targets:
                    `#${el} .${objType[el]}` +
                    (index + 1),
                  scale: [0, 1],
                  opacity: [0, 1],
                  delay: (index + 2) * 150,
                })
              })
          },
        }
        arr.push(obj)
      })

      new MyScroll({
        event: arr,
      })
    },
    isMobi () {
      if (window.innerWidth < 769) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    },
  },
  computed,
  created () {
    window.addEventListener("resize", this.isMobi)
    this.isMobi()
    this.$nextTick(() => {
      this.showAnime([
        "patient",
        "asthma_manage",
        "china_medic_identification",
      ])
    })
  },
  mounted () {
    if (this.id != '') {
      this.$nextTick(() => {
        const targetElement = this.$refs[`patient${this.id}`]
        if (targetElement) {
          targetElement.scrollIntoView(false)
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./css/index.scss";
@import "./css/patient.scss";
</style>
