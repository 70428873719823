<template>
  <Main>
    <div class="introduce" slot="main_content">
      <img
        v-if="!isMobile"
        class="introduce_img"
        src="../../assets/introduce_bg.jpg"
        alt=""
      />
      <img
        v-else
        class="introduce_img"
        src="../../assets/phone/introduce_bg.jpg"
        alt=""
      />
      <!-- 公司介绍 -->
      <div class="introduce_cont">
        <div class="introduce_cont_title">
          <div class="introduce_cont_title_txt">
            <div>公司介绍</div>
          </div>
          <div class="introduce_cont_title_tip">
            <img
              src="../../assets/blue_point.png"
              alt=""
              class="blue_pointer"
            />
          </div>
        </div>
        <div class="introduce_cont_txt">
          <img src="../../assets/introduce.png" alt="" />
          <!-- <div class="introduce_cont_txt_right" v-html="objData"></div> -->
          <div class="introduce_cont_txt_right">
            <div>
              杭州数智医济医疗科技有限公司作为中国首家专注过敏学科垂直领域的医疗服务型科技企业，已成为国内过敏领域数字医疗头部企业，企业致力于打造专注于过敏领域的AI+数字疗法平台，建立全栈线患者旅程生态体系，包含过敏医学路径重塑、患者旅程延展服务、药物数字管理服务、学术学会支撑业务等业务领域。
            </div>
            <div>
              已上市产品包括数字化过敏诊疗中心管理平台、特异性免疫治疗数字化精准管理平台、Allergy
              GPT数字过敏医生助理、过敏性单病种消费级医疗产品等众多产品。
            </div>
            <div>
              为推动过敏专科的技术发展，促进医防融合，赋能基层过敏性疾病的研究，探索其诊疗体系，深化慢性病防控与健康创新研究，加强产学研用一体化发展，努力践行“健康中国2030”战略，公司与中国疾控中心慢病中心（CDC）滨海慢性病防控与健康创新研究院共同发起成立“过敏数智研究联合实验室”，助力医疗机构完成数字过敏诊疗中心标准化建设和发展。
            </div>
            <div>
              未来，公司在完善过敏生态圈的基础上，持续推进过敏相关单病种的个性化数字疗法产品的打造，最终实现商业保险与过敏生态体系的深度结合和学科释放。
            </div>
          </div>
        </div>
      </div>
      <!-- 公司地址 -->
      <div class="map_cont">
        <div class="introduce_cont_title">
          <div class="introduce_cont_title_txt">
            <div>公司地址</div>
          </div>
          <div class="introduce_cont_title_tip">
            <img
              src="../../assets/blue_point.png"
              alt=""
              class="blue_pointer"
            />
          </div>
        </div>
        <baidu-map
          class="bm_view"
          :center="{ lng: 119.910256, lat: 30.254653 }"
          :zoom="18"
        >
          <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
          <bm-marker
            :position="{ lng: 119.910256, lat: 30.254653 }"
            :dragging="false"
            @click="infoWindowOpen"
          >
            <bm-info-window
              :show="show"
              @close="infoWindowClose"
              @open="infoWindowOpen"
            >
              <div style="font-weight: bold; font-size: 16px; color: #282d3c">
                杭州数智医济医疗科技有限公司
              </div>
              <div style="color: #777b89">
                地址：杭州市余杭区南湖未来科学园4号楼101室
              </div>
              <div style="color: #777b89">电话：0571-8858-1295</div>
            </bm-info-window>
          </bm-marker>
        </baidu-map>
      </div>
    </div>
  </Main>
</template>

<script>
import computed from "./src/computed"
import methods from "./src/methods"
import watch from "./src/watch"
import Main from "../../components/Main"

export default {
  name: "introduce",
  data () {
    return {
      objData: "",
      show: true,
      isMobile: false,
    }
  },
  components: {
    Main,
  },
  watch,
  methods,
  computed,
  created () {
    window.addEventListener("resize", this.isMobi)
    this.isMobi()
    this.getData()
  },
};
</script>

<style lang="scss" scoped>
@import "./css/index.scss";
</style>
