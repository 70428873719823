<template>
  <Main>
    <div class="foundation" slot="main_content">
      <img
        v-if="!isMobile"
        class="foundation_img"
        src="../../assets/foundation_bg.jpg"
        alt=""
      />
      <img
        v-else
        class="foundation_img"
        src="../../assets/phone/foundation_bg.jpg"
        alt=""
      />

      <!-- 简介 -->
      <div class="foundation_cont">
        <div class="foundation_title">
          <div class="foundation_title_txt">
            <div>简介</div>
          </div>
          <div class="foundation_title_tip">
            <img
              src="../../assets/blue_point.png"
              alt=""
              class="blue_pointer"
            />
          </div>
        </div>
        <div class="foundation_cont_txt">
          <div class="foundation_cont_txt_left">
            <img
              class="foundation_cont_txt_left_img"
              src="../../assets/file.png"
              alt=""
            />
            <img
              class="foundation_cont_txt_left_img_bg"
              src="../../assets/document_bg.png"
              alt=""
            />
          </div>
          <div class="foundation_cont_txt_right">
            中国初级卫生保健基金会过敏专业委员会批复于2022年12月1日，是由中国初级卫生保健基金会主管的国家级专业医学委员会。首届主任委员由程雷教授、汪慧英教授、李华斌教授共同担任。截止到2023年初，共接收委员申请33名，覆盖全国1/3省市地区。专委会设有秘书处、专委会办公室、管委会办公室、战略与发展办公室等众多部门持续性建设开展：学术交流、科研成果转化、人才引进、学科推进、专科医师培训认证、品牌建设、战略Marketing、体系建设、科研合作、健康科普、内部审计、患者援助等项工作。
            <br />
            <span>我们的宗旨：</span>
            提升专科诊疗能力，推进过敏学科发展，促进行业学术交流，建立健全开放服务平台，支持中国基层医疗、卫生、保健事业的发展；促进改善医疗卫生条件；帮助基层社区人口提高健康水平，增强健康素质和发展能力，实现脱贫致富和持续发展。
            <br />
            <span>我们的愿景：</span>
            构建适应和符合社会发展水平、满足人人能够享有、社会能够负担得起的专科医疗服务，保障与提高人民健康水平。
            <br />
            <span>我们的使命：</span>
            推动过敏医学持续性发展，构建高水平专科医疗服务体系。
          </div>
        </div>
      </div>

      <!-- 发展历程 -->
      <div class="foundation_history">
        <div class="foundation_title">
          <div class="foundation_title_txt">
            <div>发展历程</div>
          </div>
          <div class="foundation_title_tip">
            <img
              src="../../assets/blue_point.png"
              alt=""
              class="blue_pointer"
            />
          </div>
        </div>
        <div class="foundation_history_cont">
          <div class="foundation_history_list">
            <div class="foundation_history_item">
              <div class="foundation_history_item_title">2019年初</div>
              <div class="foundation_history_item_line">
                <div></div>
                <span></span>
              </div>
              <div class="foundation_history_item_img">
                <img src="../../assets/foundation_bottom.png" alt="" />
              </div>
              <div class="foundation_history_item_cont">
                <div class="foundation_history_item_cont_left"></div>
                <div class="foundation_history_item_cont_right">
                  中初保过敏专委会前身“东方过敏联盟”学术组织由苏、浙、沪、鲁、皖、闽、赣等地医学会主任委员联合发起成立。
                </div>
              </div>
            </div>
            <div class="foundation_history_item">
              <div class="foundation_history_item_title">2019年底</div>
              <div class="foundation_history_item_line">
                <div></div>
                <span></span>
              </div>
              <div class="foundation_history_item_img">
                <img src="../../assets/foundation_bottom.png" alt="" />
              </div>
              <div class="foundation_history_item_cont">
                <div class="foundation_history_item_cont_left"></div>
                <div class="foundation_history_item_cont_right">
                  召开第一次联盟大会，确认联盟工作目标与方向
                </div>
              </div>
            </div>
            <div class="foundation_history_item">
              <div class="foundation_history_item_title">2022年6月</div>
              <div class="foundation_history_item_line">
                <div></div>
                <span></span>
              </div>
              <div class="foundation_history_item_img">
                <img src="../../assets/foundation_bottom.png" alt="" />
              </div>
              <div class="foundation_history_item_cont">
                <div class="foundation_history_item_cont_left"></div>
                <div class="foundation_history_item_cont_right">
                  依托于“东方过敏联盟”学术组织基础上的中初保过敏专委会进入筹备阶段
                </div>
              </div>
            </div>
            <div class="foundation_history_item">
              <div class="foundation_history_item_title">2022年9月</div>
              <div class="foundation_history_item_line">
                <div></div>
                <span></span>
              </div>
              <div class="foundation_history_item_img">
                <img src="../../assets/foundation_bottom.png" alt="" />
              </div>
              <div class="foundation_history_item_cont">
                <div class="foundation_history_item_cont_left"></div>
                <div class="foundation_history_item_cont_right">
                  由中华医学会变态反应分会主任委员程雷教授、浙江省医学会过敏分会主任委员汪慧英教授、上海市医学会过敏分会候任主委李华斌教授联合推动，过敏专委会进入实质化推动阶段。
                </div>
              </div>
            </div>
            <div class="foundation_history_item">
              <div class="foundation_history_item_title">2022年12月1日</div>
              <div class="foundation_history_item_line">
                <div></div>
                <span></span>
              </div>
              <div class="foundation_history_item_img">
                <img src="../../assets/foundation_bottom.png" alt="" />
              </div>
              <div class="foundation_history_item_cont">
                <div class="foundation_history_item_cont_left"></div>
                <div class="foundation_history_item_cont_right">
                  中国初级卫生保健基金会正式批复，成立中国初级卫生保健基金会过敏专业委员会
                </div>
              </div>
            </div>
          </div>
          <el-carousel
            v-if="isMobile"
            :interval="5000"
            height="200px"
            arrow="always"
            indicator-position="none"
            :loop="true"
          >
            <el-carousel-item>
              <div class="foundation_history_item">
                <div class="foundation_history_item_title">2019年初</div>
                <div class="foundation_history_item_line">
                  <div></div>
                  <span></span>
                </div>
                <div class="foundation_history_item_img">
                  <img src="../../assets/foundation_bottom.png" alt="" />
                </div>
                <div class="foundation_history_item_cont">
                  <div class="foundation_history_item_cont_left"></div>
                  <div class="foundation_history_item_cont_right">
                    中初保过敏专委会前身“东方过敏联盟”学术组织由苏、浙、沪、鲁、皖、闽、赣等地医学会主任委员联合发起成立。
                  </div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="foundation_history_item">
                <div class="foundation_history_item_title">2019年底</div>
                <div class="foundation_history_item_line">
                  <div></div>
                  <span></span>
                </div>
                <div class="foundation_history_item_img">
                  <img src="../../assets/foundation_bottom.png" alt="" />
                </div>
                <div class="foundation_history_item_cont">
                  <div class="foundation_history_item_cont_left"></div>
                  <div class="foundation_history_item_cont_right">
                    召开第一次联盟大会，确认联盟工作目标与方向
                  </div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="foundation_history_item">
                <div class="foundation_history_item_title">2022年6月</div>
                <div class="foundation_history_item_line">
                  <div></div>
                  <span></span>
                </div>
                <div class="foundation_history_item_img">
                  <img src="../../assets/foundation_bottom.png" alt="" />
                </div>
                <div class="foundation_history_item_cont">
                  <div class="foundation_history_item_cont_left"></div>
                  <div class="foundation_history_item_cont_right">
                    依托于“东方过敏联盟”学术组织基础上的中初保过敏专委会进入筹备阶段
                  </div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="foundation_history_item">
                <div class="foundation_history_item_title">2022年9月</div>
                <div class="foundation_history_item_line">
                  <div></div>
                  <span></span>
                </div>
                <div class="foundation_history_item_img">
                  <img src="../../assets/foundation_bottom.png" alt="" />
                </div>
                <div class="foundation_history_item_cont">
                  <div class="foundation_history_item_cont_left"></div>
                  <div class="foundation_history_item_cont_right">
                    由中华医学会变态反应分会主任委员程雷教授、浙江省医学会过敏分会主任委员汪慧英教授、上海市医学会过敏分会候任主委李华斌教授联合推动，过敏专委会进入实质化推动阶段。
                  </div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="foundation_history_item">
                <div class="foundation_history_item_title">2022年12月1日</div>
                <div class="foundation_history_item_line">
                  <div></div>
                  <span></span>
                </div>
                <div class="foundation_history_item_img">
                  <img src="../../assets/foundation_bottom.png" alt="" />
                </div>
                <div class="foundation_history_item_cont">
                  <div class="foundation_history_item_cont_left"></div>
                  <div class="foundation_history_item_cont_right">
                    中国初级卫生保健基金会正式批复，成立中国初级卫生保健基金会过敏专业委员会
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
          <div class="foundation_history_list_copy"></div>
          <img
            class="foundation_history_img"
            src="../../assets/foundation_history_bg.png"
            alt=""
          />
        </div>
      </div>

      <!-- 足迹 -->
      <div class="foundation_footmar">
        <div class="foundation_title">
          <div class="foundation_title_txt">
            <div>足迹</div>
          </div>
          <div class="foundation_title_tip">
            <img
              src="../../assets/blue_point.png"
              alt=""
              class="blue_pointer"
            />
          </div>
        </div>
        <div class="foundation_footmar_cont">
          <img src="../../assets/footmar.png" alt="" />
          <!-- <div
            id="myChart"
            ref="myChart"
            style="max-width: 1200px; height: 50em"
          ></div> -->
        </div>
      </div>
    </div>
  </Main>
</template>

<script>
import computed from "./src/computed";
import methods from "./src/methods";
import watch from "./src/watch";
import Main from "../../components/Main";

export default {
  name: "foundation",
  data() {
    return {
      isMobile: false,
    };
  },
  components: {
    Main,
  },
  watch,
  methods,
  computed,
  created() {
    window.addEventListener('resize',this.isMobi)
    this.isMobi();
  },
};
</script>

<style lang="scss" scoped>
@import "./css/index.scss";
::v-deep .el-carousel__arrow--left,
::v-deep .el-carousel__arrow--right {
  background: white !important;
  border: 1px solid #3076fd;
  top: 50px;
  color: #3076fd !important;
  width: 20px;
  height: 20px;
  i.el-icon-arrow-right::before {
    color: #3076fd !important;
    font-weight: bold;
    font-size: 12px;
  }
  i.el-icon-arrow-left::before {
    color: #3076fd !important;
    font-weight: bold;
    font-size: 12px;
  }
}
</style>
