<template>
  <Main>
    <div class="figure" slot="main_content">
      <img
        v-if="!isMobile"
        class="figure_img"
        src="../../assets/figure.jpg"
        alt=""
      />
      <img
        v-else
        class="figure_img"
        src="../../assets/phone/figure.jpg"
        alt=""
      />

      <!-- 模型介绍 -->
      <div class="figure_model" v-if="!isMobile && !isPhone">
        <div class="figure_title">
          <div>Allergy GPT多模态医学专科大模型介绍</div>
          <img src="../../assets/blue_point.png" alt="" />
        </div>
        <div class="figure_model_cont" id="figure">
          <img
            class="figure_model_bg"
            src="../../assets/figure_bg.png"
            alt=""
          />
          <div class="figure_model_list">
            <div
              class="figure_model_item"
              :class="item.class"
              v-for="(item, index) in arrList"
              :key="index"
            >
              <div class="figure_model_item_cont">
                <div class="figure_model_item_head" :style="item.bgcolor">
                  <img
                    class="figure_model_item_head_img"
                    :src="item.img"
                    alt=""
                  />
                  <div
                    class="figure_model_item_head_cont"
                    :style="`color: ${item.color};`"
                  >
                    {{ item.title }}<span>-{{ item.desc }}</span>
                  </div>
                </div>
                <div class="figure_model_item_content">
                  <div
                    class="figure_model_item_txt"
                    v-for="(list, i) in item.list"
                    :key="i"
                  >
                    <div
                      class="figure_model_item_txt_left"
                      :style="`background-color: ${item.color};`"
                    ></div>
                    <div class="figure_model_item_txt_right">
                      {{ list }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="figure_model_item_img_cont" :class="item.bgclass">
                <div class="figure_model_item_img">
                  <img
                    class="figure_model_item_img_box"
                    src="../../assets/figure_box.png"
                    alt=""
                  />
                  <img
                    class="figure_model_item_img_line"
                    src="../../assets/figure_line.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="figure_model" id="figure1" v-if="isPhone">
        <div class="figure_title">
          <div>Allergy GPT多模态医学专科大模型介绍</div>
          <img src="../../assets/blue_point.png" alt="" />
        </div>
        <div class="figure_model_cont">
          <img
            class="figure_model_bg"
            src="../../assets/phone/figure_bg.png"
            alt=""
          />
        </div>
        <div class="figure_model_lists">
          <div
            class="figure1_model_item"
            :class="item.class1"
            v-for="(item, index) in arrList"
            :key="index"
          >
            <div class="figure_model_item_cont">
              <div class="figure_model_item_head" :style="item.bgcolor">
                <img
                  class="figure_model_item_head_img"
                  :src="item.img"
                  alt=""
                />
                <div
                  class="figure_model_item_head_cont"
                  :style="`color: ${item.color};`"
                >
                  {{ item.title }}<span>-{{ item.desc }}</span>
                </div>
              </div>
              <div class="figure_model_item_content">
                <div
                  class="figure_model_item_txt"
                  v-for="(list, i) in item.list"
                  :key="i"
                >
                  <div
                    class="figure_model_item_txt_left"
                    :style="`background-color: ${item.color};`"
                  ></div>
                  <div class="figure_model_item_txt_right">
                    {{ list }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="figure_model" id="figure1" v-if="isMobile">
        <div class="figure_title">
          <div>Allergy GPT多模态医学专科大模型介绍</div>
          <img src="../../assets/blue_point.png" alt="" />
        </div>
        <div class="figure_model_cont">
          <img
            class="figure_model_bg"
            src="../../assets/phone/figure_bg.png"
            alt=""
          />
        </div>
        <div class="figure_model_lists">
          <div
            class="figure1_model_item"
            :class="item.class1"
            v-for="(item, index) in arrList"
            :key="index"
          >
            <div class="figure_model_item_cont">
              <div class="figure_model_item_head" :style="item.bgcolor">
                <img
                  class="figure_model_item_head_img"
                  :src="item.img"
                  alt=""
                />
                <div
                  class="figure_model_item_head_cont"
                  :style="`color: ${item.color};`"
                >
                  {{ item.title }}<span>-{{ item.desc }}</span>
                </div>
              </div>
              <div class="figure_model_item_content">
                <div
                  class="figure_model_item_txt"
                  v-for="(list, i) in item.list"
                  :key="i"
                >
                  <div
                    class="figure_model_item_txt_left"
                    :style="`background-color: ${item.color};`"
                  ></div>
                  <div class="figure_model_item_txt_right">
                    {{ list }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- GPT -->
      <div class="figure_gpt">
        <div class="figure_title">
          <div>Allergy GPT</div>
          <img src="../../assets/blue_point.png" alt="" />
        </div>
        <div class="figure_gpt_tab">
          <div
            :class="[
              'figure_gpt_tab_item',
              tab_index == 0 ? 'figure_gpt_tab_item_active' : '',
            ]"
            @click="tab_index = 0"
          >
            产品介绍
          </div>
          <div
            :class="[
              'figure_gpt_tab_item',
              tab_index == 1 ? 'figure_gpt_tab_item_active' : '',
            ]"
            @click="tab_index = 1"
          >
            应用场景
          </div>
        </div>
        <div class="figure_introduce_list" v-if="tab_index == 0">
          <div class="figure_introduce_item figure_introduce_item_first">
            <div class="figure_introduce_item_cont" v-if="isMobile">
              <div class="figure_introduce_title">更便捷、贴心的个性化服务</div>
              <div class="figure_introduce_desc">
                准确识别用户需求，及时处理并响应接收到的信息，能够根据大量的医学知识、通过多方位了解用户情况，给出针对性的解决方案，提高治疗效果和满意度。
              </div>
            </div>
            <div class="figure_introduce_item_img1" id="ai">
              <img
                class="figure_introduce_item_img1_ai"
                src="../../assets/figure1.png"
                alt=""
              />
              <div class="figure_introduce_ai figure_introduce_ai1">
                <div
                  class="figure_introduce_ai_cont"
                  style="background-color: #3076fd"
                >
                  个性化
                </div>
                <div
                  class="figure_introduce_ai_point figure_introduce_ai_point_left"
                  style="background-color: #c6daff"
                >
                  <div style="background-color: #3076fd"></div>
                </div>
              </div>
              <div class="figure_introduce_ai figure_introduce_ai2">
                <div
                  class="figure_introduce_ai_cont"
                  style="background-color: #ee8e5b"
                >
                  及时响应
                </div>
                <div
                  class="figure_introduce_ai_point figure_introduce_ai_point_left"
                  style="background-color: #f6e5de"
                >
                  <div style="background-color: #ee8e5b"></div>
                </div>
              </div>
              <div class="figure_introduce_ai figure_introduce_ai3">
                <div
                  class="figure_introduce_ai_point figure_introduce_ai_point_right"
                  style="background-color: #c6daff"
                >
                  <div style="background-color: #3076fd"></div>
                </div>
                <div
                  class="figure_introduce_ai_cont"
                  style="background-color: #3076fd"
                >
                  了解用户
                </div>
              </div>
              <div class="figure_introduce_ai figure_introduce_ai4">
                <div
                  class="figure_introduce_ai_point figure_introduce_ai_point_right"
                  style="background-color: #c6daff"
                >
                  <div style="background-color: #3076fd"></div>
                </div>
                <div
                  class="figure_introduce_ai_cont"
                  style="background-color: #3076fd"
                >
                  提高疗效
                </div>
              </div>
              <div class="figure_introduce_ai figure_introduce_ai5">
                <div
                  class="figure_introduce_ai_point figure_introduce_ai_point_right"
                  style="background-color: #c6daff"
                >
                  <div style="background-color: #3076fd"></div>
                </div>
                <div
                  class="figure_introduce_ai_cont"
                  style="background-color: #3076fd"
                >
                  快 捷
                </div>
              </div>
              <div class="figure_introduce_ai figure_introduce_ai6">
                <div
                  class="figure_introduce_ai_point figure_introduce_ai_point_right"
                  style="background-color: #f6e5de"
                >
                  <div style="background-color: #ee8e5b"></div>
                </div>
                <div
                  class="figure_introduce_ai_cont"
                  style="background-color: #ee8e5b"
                >
                  解决方案
                </div>
              </div>
            </div>
            <div class="figure_introduce_item_cont" v-if="!isMobile">
              <div class="figure_introduce_title">更便捷、贴心的个性化服务</div>
              <div class="figure_introduce_desc">
                准确识别用户需求，及时处理并响应接收到的信息，能够根据大量的医学知识、通过多方位了解用户情况，给出针对性的解决方案，提高治疗效果和满意度。
              </div>
              <div
                class="figure_introduce_btn"
                @click="$store.commit('isShowChatStatus', true)"
              >
                了解详情
              </div>
            </div>
          </div>
          <div class="figure_introduce_item">
            <div class="figure_introduce_item_cont">
              <div class="figure_introduce_title">权威、可靠的过敏指导</div>
              <div class="figure_introduce_desc">
                Allergy
                GPT通过训练和学习大量权威的医学文献和临床案例，积累了丰富的过敏相关知识和经验，具备了强大的语言理解和生成能力，能够给出准确、可靠的医疗建议，减少误诊和漏诊的风险。
              </div>
              <div
                v-if="!isMobile"
                class="figure_introduce_btn"
                @click="$store.commit('isShowChatStatus', true)"
              >
                了解详情
              </div>
            </div>
            <div class="figure_introduce_item_img2" id="gpt">
              <div class="figure_introduce_item_img2_cont4">
                <div class="figure_introduce_item_img2_cont">
                  <div class="figure_introduce_item_img2_cont1">
                    <div class="figure_introduce_item_img2_cont2">
                      <div class="figure_introduce_item_img2_cont3">
                        Allergy GPT
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="figure_introduce_gpt figure_introduce_gpt1">
                语言理解&生成能力
              </div>
              <div class="figure_introduce_gpt figure_introduce_gpt2">
                生成准确可靠医疗建议
              </div>
              <div class="figure_introduce_gpt figure_introduce_gpt3">
                医学文献&临床案例
              </div>
            </div>
          </div>
          <div class="figure_introduce_item">
            <img
              v-if="!isMobile"
              class="figure_introduce_item_img3"
              src="../../assets/figure2.png"
              alt=""
            />
            <div class="figure_introduce_item_cont">
              <div class="figure_introduce_title">快速响应，助力高效诊疗</div>
              <div class="figure_introduce_desc">
                自动化回答和咨询过程，帮助医生节省时间和精力，集中精力处理更复杂和紧急的病例。满足患者临时性问诊需求，提高医生的工作效率，优化医疗资源的分配。
              </div>
              <div
                v-if="!isMobile"
                class="figure_introduce_btn"
                @click="$store.commit('isShowChatStatus', true)"
              >
                了解详情
              </div>
            </div>
            <img
              v-if="isMobile"
              class="figure_introduce_item_img3"
              src="../../assets/figure2.png"
              alt=""
            />
          </div>
          <div class="figure_introduce_item">
            <div class="figure_introduce_item_cont">
              <div class="figure_introduce_title">更专业、更匹配的问诊服务</div>
              <div class="figure_introduce_desc">
                基于过敏诊疗模型，汇集专项资源，包括专家经验、过敏科室知识等，专病专研，专科专治。更全面、更有深度的过敏数据库，能够更准确地匹配患者情况，提供优质的诊前服务。
              </div>
              <div
                v-if="!isMobile"
                class="figure_introduce_btn"
                @click="$store.commit('isShowChatStatus', true)"
              >
                了解详情
              </div>
            </div>
            <img
              class="figure_introduce_item_img4"
              src="../../assets/figure3.png"
              alt=""
            />
          </div>
          <div class="figure_introduce_item">
            <img
              v-if="!isMobile"
              class="figure_introduce_item_img5"
              src="../../assets/figure4.png"
              alt=""
            />
            <div class="figure_introduce_item_cont">
              <div class="figure_introduce_title">
                支持私有化部署，保护用户隐私
              </div>
              <div class="figure_introduce_desc">
                提供私有化部署方案，包括数据加密、权限管理、访问控制等，确保患者数据的安全。
              </div>
              <div
                v-if="!isMobile"
                class="figure_introduce_btn"
                @click="$store.commit('isShowChatStatus', true)"
              >
                了解详情
              </div>
            </div>
            <img
              v-if="isMobile"
              class="figure_introduce_item_img5"
              src="../../assets/figure4.png"
              alt=""
            />
          </div>
        </div>
        <div class="figure_value" v-if="tab_index == 1 && !isMobile">
          <div class="figure_value_cont">
            <div class="figure_value_left" v-if="society == 1">
              <div class="figure_value_left_item">
                <div class="figure_value_left_item_img">
                  <img src="../../assets/figure8.png" alt="" />
                  <div>预检导诊机器人</div>
                </div>
                <div class="figure_value_left_item_cont">
                  <div class="figure_value_left_item_title">
                    智能导诊|智能分诊|智能问答
                  </div>
                  <div class="figure_value_left_item_desc">
                    嵌入Allergy GPT医院专属大模型，彻底解决机器人“智障”的问题。
                  </div>
                </div>
              </div>
              <div class="figure_value_left_item">
                <div class="figure_value_left_item_img">
                  <img src="../../assets/figure9.png" alt="" />
                  <div>候诊AI智慧屏</div>
                </div>
                <div class="figure_value_left_item_cont">
                  <div class="figure_value_left_item_title">
                    智能候诊|健康档案|数字评估
                  </div>
                  <div class="figure_value_left_item_desc">
                    候诊区设立智慧屏，患者就诊前即可通过数字量表评估、健康档案更新等行为，智能问答与科普宣教充分结合，同时释放护士劳动力，解决医患纠纷潜在风险。
                  </div>
                </div>
              </div>
              <div class="figure_value_left_item">
                <div class="figure_value_left_item_img">
                  <img src="../../assets/figure10.png" alt="" />
                  <div>诊室桌面机器人</div>
                </div>
                <div class="figure_value_left_item_cont">
                  <div class="figure_value_left_item_title">
                    智能医评|智能随访|智能助理
                  </div>
                  <div class="figure_value_left_item_desc">
                    医患互动，解决医生评估量表繁琐性，一键入组，一键随访，通过智能助理自主管理患者。
                  </div>
                </div>
              </div>
            </div>
            <div class="figure_value_left" v-if="society == 2">
              <div class="figure_value_left_item">
                <div class="figure_value_left_item_img">
                  <img src="../../assets/figure11.png" alt="" />
                </div>
                <div class="figure_value_left_item_cont">
                  <div class="figure_value_left_item_title">
                    智能随访管理平台
                  </div>
                  <div class="figure_value_left_item_desc">
                    嵌入Allergy
                    GPT医院专科大模型，智能理解患者病情匹配随访方案，高效采集患者院外数据。
                  </div>
                </div>
              </div>
              <div class="figure_value_left_item">
                <div class="figure_value_left_item_img">
                  <img src="../../assets/figure12.png" alt="" />
                </div>
                <div class="figure_value_left_item_cont">
                  <div class="figure_value_left_item_title">
                    智能在线问诊系统
                  </div>
                  <div class="figure_value_left_item_desc">
                    嵌入Allergy
                    GPT医院专科大模型，AI医生助理线上辅助接诊，提高接诊效率，辅助诊断和治疗方案。
                  </div>
                </div>
              </div>
            </div>
            <div class="figure_value_left" v-if="society == 3">
              <div class="figure_value_left_item">
                <div class="figure_value_left_item_img">
                  <img src="../../assets/figure13.png" alt="" />
                </div>
                <div class="figure_value_left_item_cont">
                  <div class="figure_value_left_item_title">患者管家终端</div>
                  <div class="figure_value_left_item_desc">
                    不同终端嵌入不同垂直专科大模型，提供智能自诊、智能问答、用药管理、危险预警、疾病记录等众多功能。
                  </div>
                  <div class="figure_value_left_item_desc">
                    患者终端通过专科大模型与院内服务实现场景打通，有效降低人力成本，远程终端实时获取患者院外医疗数据，并可即时做出医疗服务指导和规划。
                  </div>
                </div>
              </div>
              <div class="figure_value_left_item">
                <div class="figure_value_left_item_img">
                  <img src="../../assets/figure14.png" alt="" />
                </div>
                <div class="figure_value_left_item_cont">
                  <div class="figure_value_left_item_title">智能药师终端</div>
                  <div class="figure_value_left_item_desc">
                    主动式AI用药管理，帮助医生对患者进行个性化的用药辅导和用药提醒。
                  </div>
                </div>
              </div>
            </div>
            <div class="figure_value_right">
              <div
                :class="[
                  'figure_value_right_item',
                  society == 1 ? 'figure_value_right_item_active' : '',
                ]"
                @click="society = 1"
              >
                院内硬件场景
              </div>
              <div
                :class="[
                  'figure_value_right_item',
                  society == 2 ? 'figure_value_right_item_active' : '',
                ]"
                @click="society = 2"
              >
                院内平台场景
              </div>
              <div
                :class="[
                  'figure_value_right_item',
                  society == 3 ? 'figure_value_right_item_active' : '',
                ]"
                @click="society = 3"
              >
                院外服务场景
              </div>
            </div>
          </div>
        </div>
        <div class="figure_value_list" v-if="tab_index == 1 && isMobile">
          <div class="figure_value_item">
            <div class="figure_value_item_img">
              <img src="../../assets/phone/figure8.png" alt="" />
              <div>预检导诊机器人</div>
            </div>
            <div class="figure_value_item_cont">
              <div class="figure_value_item_title">
                院内硬件场景<span>-智能导诊|智能分诊|智能问答</span>
              </div>
              <div class="figure_value_item_desc">
                嵌入Allergy GPT医院专属大模型，彻底解决机器人“智障”的问题。
              </div>
            </div>
          </div>
          <div class="figure_value_item">
            <div class="figure_value_item_img">
              <img src="../../assets/phone/figure9.png" alt="" />
              <div>候诊AI智慧屏</div>
            </div>
            <div class="figure_value_item_cont">
              <div class="figure_value_item_title">
                院内硬件场景<span>-智能候诊|健康档案|数字评估</span>
              </div>
              <div class="figure_value_item_desc">
                候诊区设立智慧屏，患者就诊前即可通过数字量表评估、健康档案更新等行为，智能问答与科普宣教充分结合，同时释放护士劳动力，解决医患纠纷潜在风险。
              </div>
            </div>
          </div>
          <div class="figure_value_item">
            <div class="figure_value_item_img">
              <img src="../../assets/phone/figure10.png" alt="" />
              <div>诊室桌面机器人</div>
            </div>
            <div class="figure_value_item_cont">
              <div class="figure_value_item_title">
                院内硬件场景<span>-智能医评|智能随访|智能助理</span>
              </div>
              <div class="figure_value_item_desc">
                医患互动，解决医生评估量表繁琐性，一键入组，一键随访，通过智能助理自主管理患者。
              </div>
            </div>
          </div>
          <div class="figure_value_item">
            <div class="figure_value_item_img">
              <img src="../../assets/phone/figure11.png" alt="" />
            </div>
            <div class="figure_value_item_cont">
              <div class="figure_value_item_title">
                院内平台场景<span>-智能随访管理平台</span>
              </div>
              <div class="figure_value_item_desc">
                嵌入Allergy
                GPT医院专科大模型，智能理解患者病情匹配随访方案，高效采集患者院外数据。
              </div>
            </div>
          </div>
          <div class="figure_value_item">
            <div class="figure_value_item_img">
              <img src="../../assets/phone/figure12.png" alt="" />
            </div>
            <div class="figure_value_item_cont">
              <div class="figure_value_item_title">
                院内平台场景<span>-智能在线问诊系统</span>
              </div>
              <div class="figure_value_item_desc">
                嵌入Allergy
                GPT医院专科大模型，AI医生助理线上辅助接诊，提高接诊效率，辅助诊断和治疗方案。
              </div>
            </div>
          </div>
          <div class="figure_value_item">
            <div class="figure_value_item_img">
              <img src="../../assets/phone/figure13.png" alt="" />
            </div>
            <div class="figure_value_item_cont">
              <div class="figure_value_item_title">
                院外服务场景<span>-患者管家终端</span>
              </div>
              <div class="figure_value_item_desc">
                不同终端嵌入不同垂直专科大模型，提供智能自诊、智能问答、用药管理、危险预警、疾病记录等众多功能。
              </div>
              <div class="figure_value_item_desc">
                患者终端通过专科大模型与院内服务实现场景打通，有效降低人力成本，
                远程终端实时获取患者院外医疗数据，并可即时做出医疗服务指导和规划。
              </div>
            </div>
          </div>
          <div class="figure_value_item">
            <div class="figure_value_item_img">
              <img src="../../assets/phone/figure14.png" alt="" />
            </div>
            <div class="figure_value_item_cont">
              <div class="figure_value_item_title">
                院外服务场景<span>-智能药师终端</span>
              </div>
              <div class="figure_value_item_desc">
                主动式AI用药管理，帮助医生对患者进行个性化的用药辅导和用药提醒。
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 产品优势 -->
      <div class="figure_superiority">
        <div class="figure_title">
          <div>产品优势</div>
          <img src="../../assets/blue_point.png" alt="" />
        </div>
        <div class="figure_superiority_list">
          <div class="figure_superiority_item">
            <img
              v-if="!isMobile"
              class="figure_superiority_item_img"
              src="../../assets/figure5.png"
              alt=""
            />
            <img
              v-else
              class="figure_superiority_item_img"
              src="../../assets/phone/figure5.png"
              alt=""
            />
            <div class="figure_superiority_item_cont">
              <div class="figure_superiority_item_title">
                权威、前沿的过敏数据库
              </div>
              <div class="figure_superiority_item_desc">
                平台与多地过敏科室和过敏领域专家达成合作，汇集了大量的优质临床数据、问诊对话数据和医生诊疗信息，泛化能力强，能够快速、准确匹配出用户症状。不断录入（更新）数据库，提高答复的适配度。
              </div>
            </div>
          </div>
          <div class="figure_superiority_item">
            <img
              v-if="!isMobile"
              class="figure_superiority_item_img"
              src="../../assets/figure6.png"
              alt=""
            />
            <img
              v-else
              class="figure_superiority_item_img"
              src="../../assets/phone/figure6.png"
              alt=""
            />
            <div class="figure_superiority_item_cont">
              <div class="figure_superiority_item_title">
                一站式过敏性疾病服务
              </div>
              <div class="figure_superiority_item_desc">
                包揽全栈线过敏诊疗服务，从AI问诊、量表评估、专家问诊，到脱敏管理、智能随访，在保护患者隐私的基础上，对临床诊疗数据进行收集，为患者提供更准确的诊疗建议、知识问答服务以及线上诊疗服务。
              </div>
            </div>
          </div>
          <div class="figure_superiority_item">
            <img
              v-if="!isMobile"
              class="figure_superiority_item_img"
              src="../../assets/figure7.png"
              alt=""
            />
            <img
              v-else
              class="figure_superiority_item_img"
              src="../../assets/phone/figure7.png"
              alt=""
            />
            <div class="figure_superiority_item_cont">
              <div class="figure_superiority_item_title">
                过敏领域先进的数字医生
              </div>
              <div class="figure_superiority_item_desc">
                采用了gpt-3.5-turbo模型，通过自有以及合作平台的临床数据不断强化训练，提高模型信息识别的精准度，同时为过敏性疾病的研究提供数据支持。具备了强大的语言理解和生成能力。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Main>
</template>

<script>
import computed from "./src/computed"
import methods from "./src/methods"
import watch from "./src/watch"
import Main from "../../components/Main"
import img1 from "../../assets/figure_model_img1.png"
import img2 from "../../assets/figure_model_img2.png"
import img3 from "../../assets/figure_model_img3.png"

export default {
  name: "figure",
  data () {
    return {
      isMobile: false,
      isPhone: false,
      arrList: [
        {
          title: '数字医疗基建',
          desc: '健康管理数智化',
          img: img1,
          list: ['智能随访管理：智能理解患者病情匹配随访方案，高效采集患者院外数据', '智能健康档案构建：将患者病例报告等数据转为以时间轴及结构化展示的健康档案系统', '疾病风险预测：基于患者健康风险评估结果，匹配精准医疗服务，提升服务转化'],
          color: '#3076fd',
          bgcolor: 'background: linear-gradient(90deg, rgba(48, 118, 253, 0.2) 0%, rgba(48, 118, 253, 0) 99%);',
          class: 'figure_model_item1',
          class1: 'figure1_model_item1',
          bgclass: 'figure_model_item_img1'
        },
        {
          title: '数字医疗基建',
          desc: '医疗数智化建设',
          img: img1,
          list: ['AI智能电话：机器人给患者打电话、发通知及收集信息', '智能在线问诊：AI医生助理线上辅助接诊，提高接诊效率，辅助诊断和治疗方案'],
          color: '#3076fd',
          bgcolor: 'background: linear-gradient(90deg, rgba(48, 118, 253, 0.2) 0%, rgba(48, 118, 253, 0) 99%);',
          class: 'figure_model_item2',
          class1: 'figure1_model_item2',
          bgclass: 'figure_model_item_img2'
        },
        {
          title: '医疗效率提升',
          desc: '提高药事服务效率',
          img: img2,
          list: ['用药管家：主动式AI用药管理，帮助医生对患者进行个性化的用药辅导和用药提醒'],
          color: '#5fc8c6',
          bgcolor: 'background: linear-gradient(90deg, rgba(95, 200, 198, 0.2) 0%, rgba(95, 200, 198, 0) 100%);',
          class: 'figure_model_item3',
          class1: 'figure1_model_item3',
          bgclass: 'figure_model_item_img3'
        },
        {
          title: '医疗效率提升',
          desc: '提升在线诊疗效率',
          img: img2,
          list: ['智能助理：辅助医生快捷回复，智能诊断、患者教育', '智能在线问诊：辅助医生提高线上问诊效率及质量的在线诊疗平台', '智能分诊：患者问诊前，由AI模拟医生智能采集患者病情并生成相关数据记录'],
          color: '#5fc8c6',
          bgcolor: 'background: linear-gradient(90deg, rgba(95, 200, 198, 0.2) 0%, rgba(95, 200, 198, 0) 100%);',
          class: 'figure_model_item4',
          class1: 'figure1_model_item4',
          bgclass: 'figure_model_item_img4'
        },
        {
          title: '数字患者服务',
          desc: '改善就医体验',
          img: img3,
          list: ['智能导诊：为患者精准推荐就诊科室及医生，降低医院转诊率', '用药管家：主动式AI用药管理，帮助医生对患者进行个性化的用药辅导和用药提醒'],
          color: '#ff9c6e',
          bgcolor: 'background: linear-gradient(90deg, rgba(255, 156, 110, 0.2) 0%, rgba(255, 156, 110, 0) 100%);',
          class: 'figure_model_item5',
          class1: 'figure1_model_item5',
          bgclass: 'figure_model_item_img5'
        },
        {
          title: '数字患者服务',
          desc: '增强患者粘性',
          img: img3,
          list: ['智能自诊：应用数字评估量表和智能交互采集患者病情，并给出疑似诊断和患者指导', '智能问答：基于Allergy GPT大模型的庞大知识库为患者答疑解惑'],
          color: '#ff9c6e',
          bgcolor: 'background: linear-gradient(90deg, rgba(255, 156, 110, 0.2) 0%, rgba(255, 156, 110, 0) 100%);',
          class: 'figure_model_item6',
          class1: 'figure1_model_item6',
          bgclass: 'figure_model_item_img6'
        }
      ],
      tab_index: 0,
      society: 1
    }
  },
  components: {
    Main
  },
  watch,
  methods,
  computed,
  created () {
    window.addEventListener("resize", this.isMobi)
    this.isMobi()
    this.$nextTick(() => {
      this.showAnime(["figure", "figure1", "ai", "gpt"])
    })
  },
};
</script>

<style lang="scss" scoped>
@import "./css/index.scss";
</style>
