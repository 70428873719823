<template>
  <div class="main_base">
    <!-- 联系我们 -->
    <div class="main_base_contact">
      <div class="main_base_contact_cont">
        <div class="main_base_contact_cont_left">
          <div class="main_base_contact_cont_left_title">联系我们</div>
          <div class="main_base_contact_cont_left_txt">
            地址：浙江省杭州市余杭区中泰街道铜山溪路2号南湖未来科学园4号楼101室
          </div>
          <div class="main_base_contact_cont_left_txt">邮箱：hr@dsmdtx.com</div>
          <div class="main_base_contact_cont_left_txt">电话：0571-88581295</div>
        </div>
        <div class="main_base_contact_cont_right">
          <div class="main_base_contact_cont_right_px">
            <div class="main_base_contact_cont_right_name">小程序</div>
            <div class="main_base_contact_cont_right_project">
              <div
                class="main_base_contact_cont_right_item main_base_contact_cont_right_item_right"
              >
                <img src="../assets/ymj.png" alt="" />
                <div>医敏+</div>
              </div>
              <div class="main_base_contact_cont_right_item">
                <img src="../assets/ymzs.png" alt="" />
                <div>医敏助手</div>
              </div>
            </div>
          </div>
          <div>
            <div class="main_base_contact_cont_right_name">服务号</div>
            <div class="main_base_contact_cont_right_project">
              <div
                class="main_base_contact_cont_right_item main_base_contact_cont_right_item_right"
              >
                <img src="../assets/szyj.png" alt="" />
                <div>"数智医济"服务号</div>
              </div>
              <div class="main_base_contact_cont_right_item">
                <img src="../assets/ym.png" alt="" />
                <div>"医敏+"订阅号</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 备案 -->
    <div class="main_base_filings">
      版权所有 © 2022-2032 杭州数智医济医疗科技有限公司
      <span>&nbsp;|&nbsp;</span>
      <span @click="getAnbei">浙公网安备 33011002016971号</span>
      <span>&nbsp;|&nbsp;</span>
      备案信息：<span @click="getBeian">浙ICP备2022034845号</span>
      <span>&nbsp;|&nbsp;</span>
      互联网药品信息服务资格证书编号：（浙）-经营性-2023-0213
    </div>
  </div>
</template>

<script>
export default {
  name: "Base",
  methods: {
    getBeian () {
      window.open("https://beian.miit.gov.cn/", "_blank")
    },
    getAnbei () {
      window.open(
        "https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33011002016971",
        "_blank"
      )
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../common.scss";

// 联系我们
.main_base_contact {
  background: url("../assets/contact_bg.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  padding: 40px;
  box-sizing: border-box;
  @include respond-to("pad") {
    padding: 30px 30px 20px;
  }
  @include respond-to("phone") {
    padding: 15px 12px;
    background: url("../assets/phone/contact_bg.png");
    background-size: 100% 100%;
  }
}

.main_base_contact_cont {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include respond-to("phone") {
    display: flex;
    flex-direction: column;
  }
}

.main_base_contact_cont_left_title {
  font-size: 34px;
  font-weight: bold;
  color: #fff;
  line-height: 49px;
  @include respond-to("pad") {
    font-size: 30px;
    line-height: 43px;
  }
  @include respond-to("phone") {
    font-size: 12px;
    line-height: 18px;
  }
}

.main_base_contact_cont_left_txt {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  @include respond-to("phone") {
    margin-top: 5px;
    font-size: 10px;
  }
}

.main_base_contact_cont_right {
  padding-top: 20px;
  box-sizing: border-box;
  display: flex;
  @include respond-to("pad") {
    padding-top: 36px;
  }
  @include respond-to("phone") {
    padding-top: 10px;
  }
}

.main_base_contact_cont_right_px {
  margin-right: 130px;
  @include respond-to("pad") {
    margin-right: 48px;
  }
  @include respond-to("phone") {
    margin-right: 40px;
  }
}

.main_base_contact_cont_right_name {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  line-height: 16px;
  @include respond-to("pad") {
    font-size: 14px;
  }
  @include respond-to("phone") {
    font-size: 12px;
  }
}

.main_base_contact_cont_right_project {
  margin-top: 16px;
  display: flex;
  @include respond-to("pad") {
    margin-top: 10px;
  }
  @include respond-to("phone") {
    margin-top: 8px;
  }
}

.main_base_contact_cont_right_item {
  text-align: center;
}

.main_base_contact_cont_right_item_right {
  margin-right: 30px;
  @include respond-to("pad") {
    margin-right: 20px;
  }
  @include respond-to("phone") {
    margin-right: 10px;
  }
}

.main_base_contact_cont_right_item > img {
  width: 80px;
  height: 80px;
  cursor: pointer;
  @include respond-to("pad") {
    width: 70px;
    height: 70px;
  }
  @include respond-to("phone") {
    width: 60px;
    height: 60px;
  }
}

.main_base_contact_cont_right_item > div {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  line-height: 12px;
  @include respond-to("pad") {
    width: 60px;
    line-height: 18px;
  }
  @include respond-to("phone") {
    font-size: 10px;
    width: 50px;
    line-height: 12px;
  }
}

// 备案
.main_base_filings {
  background-color: #252e40;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  line-height: 17px;
  padding: 17px 0;
  box-sizing: border-box;
  @include respond-to("phone") {
    font-size: 10px;
    padding: 8px 12px;
    box-sizing: border-box;
    flex-wrap: wrap;
  }
}

.main_base_filings > span {
  cursor: pointer;
}
</style>
