<template>
  <Main>
    <div class="news_detail" slot="main_content">
      <div class="news_detail_head">
        <div class="news_detail_head_title">新闻资讯</div>
        <div class="news_detail_head_name">&nbsp;-&nbsp;文章详情</div>
      </div>
      <div class="news_detail_cont">
        <div class="news_detail_title">{{ item.title }}</div>
        <div class="news_detail_time">{{ item.time }}</div>
        <div class="news_detail_content" v-html="item.content"></div>
      </div>
    </div>
  </Main>
</template>

<script>
import computed from "./src/computed";
import methods from "./src/methods";
import watch from "./src/watch";
import Main from "../../components/Main";

export default {
  name: "news_detail",
  data() {
    return {
      item: {},
    };
  },
  components: {
    Main,
  },
  watch,
  methods,
  computed,
  created() {
    this.getData(this.$route.query.newsid);
  },
};
</script>

<style lang="scss" scoped>
@import "./css/index.scss";
</style>
