<template>
  <Main>
    <div class="index" slot="main_content">
      <!-- 轮播 -->
      <div class="index_banner">
        <el-carousel :interval="3000" arrow="never">
          <el-carousel-item>
            <img v-if="isMobile" src="../../assets/phone/banner1.jpg" alt="" />
            <img v-else src="../../assets/banner1.jpg" alt="" />
          </el-carousel-item>
          <el-carousel-item>
            <img v-if="isMobile" src="../../assets/phone/banner2.jpg" alt="" />
            <img v-else src="../../assets/banner2.jpg" alt="" />
          </el-carousel-item>
          <el-carousel-item>
            <img v-if="isMobile" src="../../assets/phone/banner3.jpg" alt="" />
            <img v-else src="../../assets/banner3.jpg" alt="" />
          </el-carousel-item>
          <el-carousel-item>
            <img v-if="isMobile" src="../../assets/phone/banner4.jpg" alt="" />
            <img v-else src="../../assets/banner4.jpg" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>

      <!-- 列表 -->
      <div class="index_list" v-if="!isMobile">
        <div class="index_item" @click="$router.push('/product/patient?id=1')">
          <img class="index_item_left" src="../../assets/consult.png" alt="" />
          <div class="index_item_right">
            <div class="index_item_right_title">咨询服务</div>
            <div class="index_item_right_desc">一对一病情咨询</div>
          </div>
        </div>
        <div class="index_item" @click="$router.push('/product/patient?id=2')">
          <img
            class="index_item_left"
            src="../../assets/inventory.png"
            alt=""
          />
          <div class="index_item_right">
            <div class="index_item_right_title">量表评估</div>
            <div class="index_item_right_desc">过敏性疾病快速自查</div>
          </div>
        </div>
        <div class="index_item" @click="$router.push('/product/patient?id=6')">
          <img
            class="index_item_left"
            src="../../assets/immunoregulation.png"
            alt=""
          />
          <div class="index_item_right">
            <div class="index_item_right_title">肠道评估</div>
            <div class="index_item_right_desc">定制专属调理方案</div>
          </div>
        </div>
        <div class="index_item" @click="$router.push('/product/patient?id=7')">
          <img
            class="index_item_left"
            src="../../assets/constitution.png"
            alt=""
          />
          <div class="index_item_right">
            <div class="index_item_right_title">中医辨识</div>
            <div class="index_item_right_desc">从体质上应对疾病</div>
          </div>
        </div>
        <div class="index_item" @click="$router.push('/product/patient?id=3')">
          <img
            class="index_item_left"
            src="../../assets/desensitize.png"
            alt=""
          />
          <div class="index_item_right">
            <div class="index_item_right_title">脱敏管理</div>
            <div class="index_item_right_desc">过敏全病程管理</div>
          </div>
        </div>
      </div>

      <div class="index_phone_list_cont" v-else>
        <div class="index_phone_list">
          <div
            class="index_phone_item"
            @click="$router.push('/product/patient?id=1')"
          >
            <img
              class="index_phone_item_left"
              src="../../assets/consult.png"
              alt=""
            />
            <div class="index_phone_item_right">
              <div class="index_phone_item_right_title">咨询服务</div>
              <div class="index_phone_item_right_desc">一对一病情咨询</div>
            </div>
          </div>
          <div
            class="index_phone_item"
            @click="$router.push('/product/patient?id=2')"
          >
            <img
              class="index_phone_item_left"
              src="../../assets/inventory.png"
              alt=""
            />
            <div class="index_phone_item_right">
              <div class="index_phone_item_right_title">量表评估</div>
              <div class="index_phone_item_right_desc">过敏性疾病快速自查</div>
            </div>
          </div>
          <div
            class="index_phone_item"
            @click="$router.push('/product/patient?id=6')"
          >
            <img
              class="index_phone_item_left"
              src="../../assets/immunoregulation.png"
              alt=""
            />
            <div class="index_phone_item_right">
              <div class="index_phone_item_right_title">肠道评估</div>
              <div class="index_phone_item_right_desc">定制专属调理方案</div>
            </div>
          </div>
          <div
            class="index_phone_item"
            @click="$router.push('/product/patient?id=7')"
          >
            <img
              class="index_phone_item_left"
              src="../../assets/constitution.png"
              alt=""
            />
            <div class="index_phone_item_right">
              <div class="index_phone_item_right_title">中医辨识</div>
              <div class="index_phone_item_right_desc">从体质上应对疾病</div>
            </div>
          </div>
          <div
            class="index_phone_item"
            @click="$router.push('/product/patient?id=3')"
          >
            <img
              class="index_phone_item_left"
              src="../../assets/desensitize.png"
              alt=""
            />
            <div class="index_phone_item_right">
              <div class="index_phone_item_right_title">脱敏管理</div>
              <div class="index_phone_item_right_desc">过敏全病程管理</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 产品功能 -->
      <div class="index_function" v-if="!isMobile && !isPhone">
        <div class="index_title">
          <div>产品功能</div>
          <img src="../../assets/blue_point.png" alt="" />
        </div>
        <div class="index_function_cont" id="function">
          <div class="index_function_content">
            <img
              class="index_function_bg_img"
              src="../../assets/function_bg.png"
              alt=""
            />
            <div class="index_function_list">
              <div class="index_function_item index_function_item1">
                医敏助手
              </div>
              <div class="index_function_item index_function_item2">
                过敏科室管理系统
              </div>
              <div class="index_function_item index_function_item3">医敏+</div>
            </div>
            <div class="index_function_tem">
              <div
                v-for="(item, index) in arrList"
                :key="index"
                class="index_function_tem_item"
                :class="item.class"
                :style="`text-align: ${item.align};`"
              >
                <div
                  class="index_function_tem_item_title"
                  :style="`color: ${item.color};`"
                >
                  {{ item.title }}
                </div>
                <div class="index_function_tem_item_desc">
                  {{ item.desc }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="index_function" v-else>
        <div class="index_title">
          <div>产品功能</div>
          <img src="../../assets/blue_point.png" alt="" />
        </div>
        <div class="index_phone_function_cont" id="function">
          <img src="../../assets/function_bg.png" alt="" />
          <div class="index_phone_function_cont1">医敏助手</div>
          <div class="index_phone_function_cont2">过敏科室管理系统</div>
          <div class="index_phone_function_cont3">医敏+</div>
        </div>
        <div class="index_phone_function_list">
          <div
            class="index_phone_function_item"
            v-for="(item, index) in arrList"
            :class="item.class"
            :key="index"
          >
            <div
              class="index_phone_function_item_title"
              :style="`color: ${item.color};`"
            >
              {{ item.title }}
            </div>
            <div class="index_phone_function_item_desc">{{ item.desc }}</div>
          </div>
        </div>
      </div>

      <!-- 涉及领域 -->
      <div class="index_field" v-if="!isMobile">
        <div class="index_title">
          <div>涉及领域</div>
          <img src="../../assets/blue_point.png" alt="" />
        </div>
        <img
          class="index_field_bg"
          src="../../assets/right_top_pointer.png"
          alt=""
        />
        <div class="index_field_cont">
          <div class="index_field_left" id="area_anime">
            <div class="index_field_left1"></div>
            <img
              class="index_field_left_img"
              src="../../assets/field_bg.png"
              alt=""
            />
            <div class="index_field_list">
              <div class="index_field_item index_field_item1">
                <img src="../../assets/jmy.png" alt="" />
                <div>结膜炎</div>
              </div>
              <div class="index_field_item index_field_item2">
                <img src="../../assets/xmz.png" alt="" />
                <div>荨麻疹</div>
              </div>
              <div class="index_field_item index_field_item3">
                <img src="../../assets/sz.png" alt="" />
                <div>湿疹</div>
              </div>
              <div class="index_field_item index_field_item4">
                <img src="../../assets/more.png" alt="" />
                <div>更多</div>
              </div>
              <div class="index_field_item index_field_item5">
                <img src="../../assets/gmxxc.png" alt="" />
                <div>过敏性哮喘</div>
              </div>
              <div class="index_field_item index_field_item6">
                <img src="../../assets/gmxby.png" alt="" />
                <div>过敏性鼻炎</div>
              </div>
            </div>
          </div>
          <div class="index_field_right">
            <div class="index_field_right_item">
              <div class="index_field_right_item_title">呼吸系统过敏</div>
              <div class="index_field_right_item_desc">
                过敏性鼻炎、过敏性支气管炎、过敏性哮喘……
              </div>
            </div>
            <div class="index_field_right_item">
              <div class="index_field_right_item_title">皮肤过敏</div>
              <div class="index_field_right_item_desc">
                湿疹、药疹、荨麻疹、接触性皮炎等……
              </div>
            </div>
            <div class="index_field_right_item">
              <div class="index_field_right_item_title">消化道过敏</div>
              <div class="index_field_right_item_desc">
                胃绞痛、腹胀、腹泻……
              </div>
            </div>
            <div class="index_field_right_item">
              <div class="index_field_right_item_title">眼部过敏</div>
              <div class="index_field_right_item_desc">过敏性结膜炎……</div>
            </div>
          </div>
        </div>
      </div>

      <div class="index_field" v-else>
        <div class="index_title">
          <div>涉及领域</div>
          <img src="../../assets/blue_point.png" alt="" />
        </div>
        <img
          class="index_field_bg"
          src="../../assets/right_top_pointer.png"
          alt=""
        />
        <div class="index_phone_field">
          <div class="index_field_left" id="area_anime">
            <div class="index_field_left1"></div>
            <img
              class="index_field_left_img"
              src="../../assets/field_bg.png"
              alt=""
            />
            <div class="index_field_list">
              <div class="index_field_item index_field_item1">
                <img src="../../assets/jmy.png" alt="" />
                <div>结膜炎</div>
              </div>
              <div class="index_field_item index_field_item2">
                <img src="../../assets/xmz.png" alt="" />
                <div>荨麻疹</div>
              </div>
              <div class="index_field_item index_field_item3">
                <img src="../../assets/sz.png" alt="" />
                <div>湿疹</div>
              </div>
              <div class="index_field_item index_field_item4">
                <img src="../../assets/more.png" alt="" />
                <div>更多</div>
              </div>
              <div class="index_field_item index_field_item5">
                <img src="../../assets/gmxxc.png" alt="" />
                <div>过敏性哮喘</div>
              </div>
              <div class="index_field_item index_field_item6">
                <img src="../../assets/gmxby.png" alt="" />
                <div>过敏性鼻炎</div>
              </div>
            </div>
          </div>
        </div>
        <div class="index_phone_field_list">
          <div class="index_phone_field_item">
            <div class="index_phone_field_item_title">呼吸系统过敏</div>
            <div class="index_phone_field_item_desc">
              过敏性鼻炎、过敏性支气管炎、过敏性哮喘……
            </div>
          </div>
          <div class="index_phone_field_item">
            <div class="index_phone_field_item_title">皮肤过敏</div>
            <div class="index_phone_field_item_desc">
              湿疹、药疹、荨麻疹、接触性皮炎等……
            </div>
          </div>
          <div class="index_phone_field_item">
            <div class="index_phone_field_item_title">消化道过敏</div>
            <div class="index_phone_field_item_desc">胃绞痛、腹胀、腹泻……</div>
          </div>
          <div class="index_phone_field_item">
            <div class="index_phone_field_item_title">眼部过敏</div>
            <div class="index_phone_field_item_desc">过敏性结膜炎……</div>
          </div>
        </div>
      </div>

      <!-- 资质证书 -->
      <div class="index_certificate">
        <div class="index_title">
          <div>资质证书</div>
          <img src="../../assets/blue_point.png" alt="" />
        </div>
        <!-- <div style="max-width: 1200px; overflow: hidden; margin: 0 auto">
          <div class="scroll-content">
            <div class="index_certificate_list">
              <div class="index_certificate_item">
                <el-image
                  class="index_certificate_item_img"
                  :src="require('../../assets/certificate1.png')"
                  :preview-src-list="[require('../../assets/certificate1.png')]"
                >
                </el-image>
              </div>
              <div class="index_certificate_item">
                <el-image
                  class="index_certificate_item_img"
                  :src="require('../../assets/certificate2.png')"
                  :preview-src-list="[require('../../assets/certificate2.png')]"
                >
                </el-image>
              </div>
              <div class="index_certificate_item">
                <el-image
                  class="index_certificate_item_img"
                  :src="require('../../assets/certificate3.png')"
                  :preview-src-list="[require('../../assets/certificate3.png')]"
                >
                </el-image>
              </div>
              <div class="index_certificate_item">
                <el-image
                  class="index_certificate_item_img"
                  :src="require('../../assets/certificate4.png')"
                  :preview-src-list="[require('../../assets/certificate4.png')]"
                >
                </el-image>
              </div>
              <div class="index_certificate_item">
                <el-image
                  class="index_certificate_item_img"
                  :src="require('../../assets/certificate5.png')"
                  :preview-src-list="[require('../../assets/certificate5.png')]"
                >
                </el-image>
              </div>
              <div class="index_certificate_item">
                <el-image
                  class="index_certificate_item_img"
                  :src="require('../../assets/certificate6.png')"
                  :preview-src-list="[require('../../assets/certificate6.png')]"
                >
                </el-image>
              </div>
              <div class="index_certificate_item">
                <el-image
                  class="index_certificate_item_img"
                  :src="require('../../assets/certificate7.png')"
                  :preview-src-list="[require('../../assets/certificate7.png')]"
                >
                </el-image>
              </div>
            </div>
            <div class="index_certificate_list">
              <div class="index_certificate_item">
                <el-image
                  class="index_certificate_item_img"
                  :src="require('../../assets/certificate1.png')"
                  :preview-src-list="[require('../../assets/certificate1.png')]"
                >
                </el-image>
              </div>
              <div class="index_certificate_item">
                <el-image
                  class="index_certificate_item_img"
                  :src="require('../../assets/certificate2.png')"
                  :preview-src-list="[require('../../assets/certificate2.png')]"
                >
                </el-image>
              </div>
              <div class="index_certificate_item">
                <el-image
                  class="index_certificate_item_img"
                  :src="require('../../assets/certificate3.png')"
                  :preview-src-list="[require('../../assets/certificate3.png')]"
                >
                </el-image>
              </div>
              <div class="index_certificate_item">
                <el-image
                  class="index_certificate_item_img"
                  :src="require('../../assets/certificate4.png')"
                  :preview-src-list="[require('../../assets/certificate4.png')]"
                >
                </el-image>
              </div>
              <div class="index_certificate_item">
                <el-image
                  class="index_certificate_item_img"
                  :src="require('../../assets/certificate5.png')"
                  :preview-src-list="[require('../../assets/certificate5.png')]"
                >
                </el-image>
              </div>
              <div class="index_certificate_item">
                <el-image
                  class="index_certificate_item_img"
                  :src="require('../../assets/certificate6.png')"
                  :preview-src-list="[require('../../assets/certificate6.png')]"
                >
                </el-image>
              </div>
              <div class="index_certificate_item">
                <el-image
                  class="index_certificate_item_img"
                  :src="require('../../assets/certificate7.png')"
                  :preview-src-list="[require('../../assets/certificate7.png')]"
                >
                </el-image>
              </div>
            </div>
          </div>
        </div> -->
        <div class="scroll-container">
          <div
            v-for="(item, index) in srcList"
            class="scroll-item"
            :key="index"
            :style="styleFormatter(index)"
            ref="scrollItem"
          >
            <el-image
              class="index_certificate_item_img"
              style="
                padding: 10px;
                box-shadow: 0 0 9px 0 rgba(48, 118, 253, 0.16);
              "
              :src="item.src"
              :preview-src-list="item.url"
            >
            </el-image>
          </div>
          <!-- <div class="scroll-item" :style="styleFormatter(1)" ref="scrollItem">
            <el-image
              class="index_certificate_item_img"
              :src="require('../../assets/certificate2.png')"
              :preview-src-list="[require('../../assets/certificate2.png')]"
            >
            </el-image>
          </div>
          <div class="scroll-item" :style="styleFormatter(2)" ref="scrollItem">
            <el-image
              class="index_certificate_item_img"
              :src="require('../../assets/certificate3.png')"
              :preview-src-list="[require('../../assets/certificate3.png')]"
            >
            </el-image>
          </div>
          <div class="scroll-item" :style="styleFormatter(3)" ref="scrollItem">
            <el-image
              class="index_certificate_item_img"
              :src="require('../../assets/certificate4.png')"
              :preview-src-list="[require('../../assets/certificate4.png')]"
            >
            </el-image>
          </div>
          <div class="scroll-item" :style="styleFormatter(4)" ref="scrollItem">
            <el-image
              class="index_certificate_item_img"
              :src="require('../../assets/certificate5.png')"
              :preview-src-list="[require('../../assets/certificate5.png')]"
            >
            </el-image>
          </div>
          <div class="scroll-item" :style="styleFormatter(5)" ref="scrollItem">
            <el-image
              class="index_certificate_item_img"
              :src="require('../../assets/certificate6.png')"
              :preview-src-list="[require('../../assets/certificate6.png')]"
            >
            </el-image>
          </div>
          <div class="scroll-item" :style="styleFormatter(6)" ref="scrollItem">
            <el-image
              class="index_certificate_item_img"
              :src="require('../../assets/certificate7.png')"
              :preview-src-list="[require('../../assets/certificate7.png')]"
            >
            </el-image>
          </div> -->
        </div>
      </div>

      <!-- 合作专家 -->
      <div class="index_expert">
        <div class="index_title">
          <div>合作专家</div>
          <img src="../../assets/blue_point.png" alt="" />
        </div>
        <img
          class="index_expert_bg"
          src="../../assets/left_bottom_pointer.png"
          alt=""
        />
        <div class="index_expert_list">
          <div
            class="index_expert_item"
            v-for="(item, index) in expertList"
            :key="index"
          >
            <img class="index_expert_item_avatar" :src="item.avatar" alt="" />
            <div class="index_expert_item_name">
              {{ item.name }}
            </div>
            <div class="index_expert_item_hospital">
              {{ item.hospital }}
            </div>
            <div class="index_expert_item_desc">
              {{ item.desc }}
            </div>
          </div>
        </div>
      </div>

      <!-- 合作伙伴 -->
      <div class="index_hospital">
        <div class="index_title">
          <div>合作伙伴</div>
          <img src="../../assets/blue_point.png" alt="" />
        </div>
        <!-- <div class="index_hospital_img">
          <el-carousel :interval="5000" arrow="never">
            <el-carousel-item>
              <img src="../../assets/hospital1.png" alt="" />
              <img src="../../assets/hospital2.png" alt="" />
              <img src="../../assets/hospital3.png" alt="" />
              <img src="../../assets/hospital4.png" alt="" />
              <img src="../../assets/hospital5.png" alt="" />
            </el-carousel-item>
            <el-carousel-item>
              <img src="../../assets/hospital6.png" alt="" />
              <img src="../../assets/hospital7.png" alt="" />
              <img src="../../assets/hospital8.png" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div> -->
        <div class="index_partner" style="margin-top: 40px">
          <div
            class="index_partner_item"
            v-for="(item, index) in 11"
            :key="index"
            :style="`left: ${index * 250}px`"
            ref="partnerItem1"
          >
            <img
              class="index_partner_item_img"
              :src="require(`../../assets/cooperate/group1/${index + 1}.png`)"
            />
          </div>
        </div>
        <div class="index_partner" style="margin-top: 20px">
          <div
            class="index_partner_item"
            v-for="(item, index) in 11"
            :key="index"
            :style="`left: ${index * 250}px`"
            ref="partnerItem2"
          >
            <img
              class="index_partner_item_img"
              :src="require(`../../assets/cooperate/group2/${index + 1}.png`)"
            />
          </div>
        </div>
        <div class="index_partner" style="margin-top: 20px">
          <div
            class="index_partner_item"
            v-for="(item, index) in 10"
            :key="index"
            :style="`left: ${index * 250}px`"
            ref="partnerItem3"
          >
            <img
              class="index_partner_item_img"
              :src="require(`../../assets/cooperate/group3/${index + 1}.png`)"
            />
          </div>
        </div>
        <div class="index_partner" style="margin-top: 20px">
          <div
            class="index_partner_item"
            v-for="(item, index) in 10"
            :key="index"
            :style="`left: ${index * 250}px`"
            ref="partnerItem4"
          >
            <img
              class="index_partner_item_img"
              :src="require(`../../assets/cooperate/group4/${index + 1}.png`)"
            />
          </div>
        </div>
      </div>

      <!-- 合作共赢 -->
      <div class="index_cooperate">
        <div class="index_title">
          <div>合作共赢</div>
          <img src="../../assets/blue_point.png" alt="" />
        </div>
        <div class="index_cooperate_cont">
          <div class="index_cooperate_ipt">
            <el-form
              :inline="true"
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              class="demo-form-inline"
            >
              <el-form-item :label="isMobile ? '' : '姓名'" prop="nickname">
                <el-input
                  v-model="ruleForm.nickname"
                  placeholder="请输入姓名"
                  class="index_cooperate_input"
                ></el-input>
              </el-form-item>
              <el-form-item :label="isMobile ? '' : '手机号'" prop="mobile">
                <el-input
                  v-model="ruleForm.mobile"
                  placeholder="请输入手机号"
                  class="index_cooperate_input"
                  :maxlength="11"
                ></el-input>
              </el-form-item>
              <el-form-item :label="isMobile ? '' : '留言'" prop="content">
                <el-input
                  v-model="ruleForm.content"
                  placeholder="请输入留言内容"
                  class="index_cooperate_txt"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  size="medium"
                  @click="onSubmit"
                  :loading="loading"
                >
                  提交
                </el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="index_cooperate_tip">
            免费咨询、专业解答，24小时为您服务，请您放心接听
          </div>
        </div>
      </div>
    </div>
  </Main>
</template>

<script>
import computed from "./src/computed"
import methods from "./src/methods"
import watch from "./src/watch"
import Main from "../../components/Main"
import why from "../../assets/why.png"
import cl from "../../assets/cl.png"
import lmp from "../../assets/lmp.png"
import lhb from "../../assets/lhb.png"

export default {
  name: "index",
  data () {
    return {
      isMobile: false,
      isPhone: false,
      // 每个元素的宽度
      width: 270,
      // 滚动速度
      speed: 100,
      arrList: [
        {
          title: "科研数据管理",
          desc: "诊疗信息同步回传，一站式数据管理",
          class: "index_function_tem_item_left1",
          color: "#5fc8c6",
          align: "right",
        },
        {
          title: "科室业务管理",
          desc: "业务运作线上化，协同管理更高效",
          class: "index_function_tem_item_left2",
          color: "#5fc8c6",
          align: "right",
        },
        {
          title: "AI专科随访",
          desc: "智能专科随访，诊疗动态全响应",
          class: "index_function_tem_item_left3",
          color: "#5fc8c6",
          align: "right",
        },
        {
          title: "电子医嘱",
          desc: "医嘱一键送达，患者管理更便捷",
          class: "index_function_tem_item_left4",
          color: "#5fc8c6",
          align: "right",
        },
        {
          title: "病情监测",
          desc: "记录评估同步进行，诊疗效果全洞悉",
          class: "index_function_tem_item_left5",
          color: "#ee8e5b",
          align: "right",
        },
        {
          title: "智能提醒",
          desc: "脱敏计划全跟踪，诊疗过程无遗漏",
          class: "index_function_tem_item_left6",
          color: "#ee8e5b",
          align: "center",
        },
        {
          title: "过敏评估",
          desc: "量表数字化体系，多参合诊更精准",
          class: "index_function_tem_item_right6",
          color: "#ee8e5b",
          align: "center",
        },
        {
          title: "脱敏管理",
          desc: "诊疗流程标准化，脱敏管理更科学",
          class: "index_function_tem_item_right5",
          color: "#ee8e5b",
          align: "left",
        },
        {
          title: "实时沟通",
          desc: "病情反馈更及时，方案调整更适配",
          class: "index_function_tem_item_right4",
          color: "#3076fd",
          align: "left",
        },
        {
          title: "患者管理",
          desc: "诊疗信息全获悉，分类管理更高效",
          class: "index_function_tem_item_right3",
          color: "#3076fd",
          align: "left",
        },
        {
          title: "病程管理",
          desc: "患者病程监控，病情把握更准确",
          class: "index_function_tem_item_right2",
          color: "#3076fd",
          align: "left",
        },
        {
          title: "医患绑定",
          desc: "患者医生一对一，沟通追踪更简单",
          class: "index_function_tem_item_right1",
          color: "#3076fd",
          align: "left",
        },
      ],
      expertList: [
        {
          name: "汪慧英",
          hospital: "浙江大学医学院附属第二医院主任医师、医学博士",
          desc: "擅长：过敏性鼻炎，哮喘、结膜炎、过敏性咳嗽、荨麻疹、特应性皮炎",
          avatar: why,
        },
        {
          name: "程雷",
          hospital: "江苏省人民医院主任医师、医学博士",
          desc: "擅长：鼻部疾病和过敏疾病",
          avatar: cl,
        },
        {
          name: "陆美萍",
          hospital: "江苏省人民医院主任医师、医学博士",
          desc: "擅长：过敏性鼻炎、血管变应性鼻炎",
          avatar: lmp,
        },
        {
          name: "李华斌",
          hospital: "复旦大学附属眼耳鼻喉科研究院副院长",
          desc: "擅长：慢性鼻窦炎、过敏性鼻炎",
          avatar: lhb,
        },
      ],
      srcList: [],
      loading: false,
      ruleForm: {
        nickname: "",
        mobile: "",
        content: "",
      },
      rules: {
        mobile: [
          {
            required: true,
            message: "请输入电话",
            trigger: "blur",
          },
          {
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "电话格式有误!",
            trigger: "blur",
          },
        ],
      },
    }
  },
  components: {
    Main,
  },
  watch,
  methods,
  computed,
  mounted () {
    for (let i = 0; i < 11; i++) {
      this.srcList.push({
        src: require("../../assets/certificate" + (i + 1) + ".png"),
        url: [require("../../assets/certificate" + (i + 1) + ".png")],
      })
    }
    this.$nextTick(() => {
      this.showAnime(["function", "area_anime"])
      this.isMobi()
      if (this.isMobile) {
      }
      this.initListener()
      this.initListener1(this.$refs.partnerItem1, 100)
      this.initListener1(this.$refs.partnerItem2, 90)
      this.initListener1(this.$refs.partnerItem3, 110)
      this.initListener1(this.$refs.partnerItem4, 120)
    })
    window.addEventListener("resize", this.isMobi)
  },
};
</script>

<style lang="scss" scoped>
@import "./css/index.scss";
</style>
