<template>
  <Main>
    <div class="operate" slot="main_content">
      <img v-if="!isMobile" class="product_img" src="../../assets/operate_bg.jpg" alt="" />
      <img
        v-else
        class="product_img"
        src="../../assets/phone/operate_bg.jpg"
        alt=""
      />
      <div class="operate_cont">
        <div class="flex jcc aic">
          <div class="cont_title">
            <div class="cont_title_txt">
              <div>产品介绍</div>
            </div>
            <div class="cont_title_tip">
              <img
                src="../../assets/blue_point.png"
                alt=""
                class="blue_pointer"
              />
            </div>
          </div>
        </div>
        <div class="list">
          <div class="item">
            <div class="item_cont_container">
              <div class="item_title">清肺利咽代茶饮</div>
              <div class="item_text">
                根据古方调配、不含激素药物。过敏病人可进行长期饮用。可调整病人过敏体质，增强免疫力。
              </div>
              <div class="item_sub_title">主要成分</div>
              <div class="flex jcsb" style="margin-top: 10px">
                <div class="flexcol aic medic">
                  <img class="medic_img" src="../../assets/medic1.png" alt="" />
                  <div class="medic_name">白术</div>
                </div>
                <div class="flexcol aic medic">
                  <img class="medic_img" src="../../assets/medic2.png" alt="" />
                  <div class="medic_name">炙甘草</div>
                </div>
                <div class="flexcol aic medic">
                  <img class="medic_img" src="../../assets/medic3.png" alt="" />
                  <div class="medic_name">防风</div>
                </div>
                <div class="flexcol aic medic">
                  <img class="medic_img" src="../../assets/medic4.png" alt="" />
                  <div class="medic_name">桔梗</div>
                </div>
              </div>
            </div>
            <div class="img_cont">
              <img src="../../assets/operate_product1.png" alt="" />
            </div>
          </div>
          <div class="item">
            <div class="item_cont_container">
              <div class="item_title">草本花颜</div>
              <div class="item_text">
                以古方为基础底方，结合中药有效成分萃取技术，提取多种中药材有效成分，作用于肌肤微炎症处，从临床出发，通过采取控制皮肤微炎症状态来缓解过敏性皮肤症状。
              </div>
            </div>
            <div class="img_cont">
              <img src="../../assets/operate_product2.png" alt="" />
            </div>
          </div>
          <div class="item">
            <div class="item_cont_container">
              <div class="item_title">中医智能全诊仪</div>
              <div class="item_text">
                集成舌面图像分析、脉诊数据采集、耳穴/体穴阻抗测量、证型识别和体质辨识等功能。通过高分辨率摄像头、脉搏传感器和电极传感器等技术，能够非侵入性地采集用户的生理数据，并利用算法和知识库分析，为用户提供准确的中医诊断和个性化的治疗建议。
              </div>
            </div>
            <div class="img_cont">
              <img src="../../assets/operate_product3.png" alt="" />
            </div>
          </div>
          <div class="item">
            <div class="item_cont_container">
              <div class="item_title">阻断型激素药物传感器</div>
              <div class="item_text">
                药物使用时，通过监测体内激素水平的变化来指导激素药物的使用。传感器可以实时监测体内激素的水平，并将数据传输到手机应用程序中进行分析和记录。患者可以更准确地使用激素药物，减少过量使用的风险，同时确保在需要时及时使用。数据传输到云服务器进行数据的聚合、分析，同时，医师也能够了解患者的用药及医嘱执行情况，更深入的了解过敏性疾病的触发因素，提供个性化的医疗解决方案。
              </div>
            </div>
            <div class="img_cont">
              <img src="../../assets/operate_product4.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Main>
</template>

<script>
import computed from "./src/computed";
import methods from "./src/methods";
import watch from "./src/watch";
import Main from "../../components/Main";

export default {
  name: "operate",
  data() {
    return {
      medicList: [
        {
          url: "",
        },
      ],
      isMobile: false,
    };
  },
  components: {
    Main,
  },
  watch,
  methods,
  computed,
  created() {
    window.addEventListener("resize", this.isMobi);
    this.isMobi();
  },
};
</script>

<style lang="scss" scoped>
@import "./css/index.scss";
@import "./css/operate.scss";
</style>
