/**
 * 常规方法定义
 * 1、不能用()=>{}  箭头函数定义
 * 2、大量计算方法建议放computed
 */
import { post } from "../../../http/http"
import anime from "animejs"

export default {
  onSubmit () {
    this.$refs["ruleForm"].validate((valid) => {
      if (valid) {
        this.loading = true
        post(this.$api.public.saveFreeData, this.ruleForm)
          .then((res) => {
            this.loading = false
            if (res.code == 200) {
              this.$message.success("提交成功")
              this.$refs["ruleForm"].resetFields()
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((err) => {
            this.$message.error(err.msg)
          })
      }
    })
  },
  isMobi () {
    if (window.innerWidth < 800) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }

    if (window.innerWidth < 1000) {
      this.isPhone = true
    } else {
      this.isPhone = false
    }
  },
  showAnime (typeArr) {
    let arr = []
    let _this = this
    let objType = {
      function: "index_function_tem_item",
      area_anime: "index_field_item",
    }
    typeArr.forEach((el) => {
      if (!document.querySelector(`#${el}`)) return
      let obj = {
        el: document.querySelector(`#${el}`), //触发条件 支持px和百分比
        loop: false, //是否循环使用 true/false 设置false后执行执行一次后会销毁
        type: "all", //触发方式 支持up-down down-up all三种方式
        func: function () {
          anime({
            targets: `#${el}`,
            scale: [0, 1],
            opacity: [0, 1],
            duration: 3000,
            delay: 0,
          })
          if (el === "function") {
            anime({
              targets: ".index_function_list",
              scale: [0, 1],
              opacity: [0, 1],
              delay: 550,
            })
          }
          document
            .querySelectorAll(`#${el} .${objType[el]}`)
            .forEach((item, index) => {
              if (el === "function") {
                if (
                  index >
                  document.querySelectorAll(`#${el} .${objType[el]}`).length / 2
                )
                  return
                anime({
                  targets: `#${el} .${objType[el]}_left` + (index + 1),
                  scale: [0, 1],
                  opacity: [0, 1],
                  delay: (index + 1) * 150 + 700,
                })
                anime({
                  targets: `#${el} .${objType[el]}_right` + (index + 1),
                  scale: [0, 1],
                  opacity: [0, 1],
                  delay: (index + 1) * 150 + 700,
                })
                return
              }
              anime({
                targets: `#${el} .${objType[el]}` + (index + 1),
                scale: [0, 1],
                opacity: [0, 1],
                delay: (index + 1) * 150 + 1000,
              })
            })
        },
      }
      arr.push(obj)
    })

    new MyScroll({
      event: arr,
    })
  },

  // 根据索引计算当前元素所在位置
  styleFormatter (index) {
    return {
      left: `${index * this.width}px`,
    }
  },
  // 控制滚动开始
  scroll () {
    const elementList = this.$refs.scrollItem
    elementList.forEach((ele) => {
      ele.style.transitionDuration = `${(ele.offsetLeft + this.width) / this.speed
        }s`
      ele.style.left = `-${this.width}px`
    })
  }, // 初始化listener，监听滚动动画
  // 当元素完全滚动至显示范围外时，则将该元素插入队尾
  initListener () {
    const elementList = this.$refs.scrollItem
    this.scroll()

    elementList.forEach((ele) => {
      // 当动画结束后会触发transitionend事件
      ele.addEventListener("transitionend", () => {
        // 计算队尾位置
        const latestElementPosition = this.latestElementPosition()
        ele.style.transitionDuration = `0s`
        ele.style.left = `${latestElementPosition + this.width}px`

        // 渲染完毕之后开始滚动
        this.$nextTick(() => {
          ele.style.transitionDuration = `${(ele.offsetLeft + this.width) / this.speed
            }s`
          ele.style.left = `-${this.width}px`
        })
      })
    })
  },
  // 计算最后一个元素的位置
  latestElementPosition () {
    const elementList = this.$refs.scrollItem
    return Math.max(
      ...elementList.map((ele) => {
        // 注意，这里不能直接读取ele.style.left，这样只能读到终点位置的偏移量，而我们需要的是当前元素的实际位置，需要通过getComputedStyle来获取
        const styles = getComputedStyle(ele, null)
        return parseFloat(styles.left)
      })
    )
  },
  // 合作伙伴
  scroll1 (elementList, time) {
    elementList.forEach((ele) => {
      ele.style.transitionDuration = `${(ele.offsetLeft + 250) / time}s`
      ele.style.left = `-250px`
    })
  },
  initListener1 (elementList, time) {
    this.scroll1(elementList, time)

    elementList.forEach((ele) => {
      ele.addEventListener("transitionend", () => {
        const latestElementPosition = this.latestElementPosition1(elementList)
        ele.style.transitionDuration = `0s`
        ele.style.left = `${latestElementPosition + 250}px`

        this.$nextTick(() => {
          ele.style.transitionDuration = `${(ele.offsetLeft + 250) / time}s`
          ele.style.left = `-250px`
        })
      })
    })
  },
  latestElementPosition1 (elementList) {
    return Math.max(
      ...elementList.map((ele) => {
        const styles = getComputedStyle(ele, null)
        return parseFloat(styles.left)
      })
    )
  }
}
