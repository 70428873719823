import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/index.vue'
import Product from '../views/Product/index.vue'
import Patient from '../views/Product/patient.vue'
import Doctor from '../views/Product/doctor.vue'
import Saas from '../views/Product/saas.vue'
import Operate from '../views/Product/operate.vue'
import Drug from '../views/Product/drug.vue'
import Apparatus from '../views/Product/apparatus.vue'
import Figure from '../views/Figure/index.vue'
import News from '../views/News/index.vue'
import NewsDetail from '../views/NewsDetail/index.vue'
import Sensitive from '../views/Sensitive/index.vue'
import Foundation from '../views/Foundation/index.vue'
import Introduce from '../views/Introduce/index.vue'
import Material from '../views/Material/index.vue'
import Write from '../views/Write/index.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: Home
    },
    {
        path: '/product',
        name: 'product',
        component: Product
    },
    {
        path: '/product/patient',
        name: 'patient',
        component: Patient
    },
    {
        path: '/product/doctor',
        name: 'doctor',
        component: Doctor
    },
    {
        path: '/product/saas',
        name: 'saas',
        component: Saas
    },
    {
        path: '/product/operate',
        name: 'operate',
        component: Operate
    },
    {
        path: '/product/drug',
        name: 'drug',
        component: Drug
    },
    {
        path: '/product/apparatus',
        name: 'apparatus',
        component: Apparatus
    },
    {
        path: '/figure',
        name: 'figure',
        component: Figure
    },
    {
        path: '/news',
        name: 'news',
        component: News
    },
    {
        path: '/news/detail',
        name: 'news_detail',
        component: NewsDetail
    },
    {
        path: '/sensitive',
        name: 'sensitive',
        component: Sensitive
    },
    {
        path: '/foundation',
        name: 'foundation',
        component: Foundation
    },
    {
        path: '/introduce',
        name: 'introduce',
        component: Introduce
    },
    {
        path: '/material',
        name: 'material',
        meta: {
            title: '中初保过敏专委会成员信息填报系统'
        },
        component: Material
    },
    {
        path: '/write',
        name: 'write',
        meta: {
            title: '东北过敏联盟成员单位申请表'
        },
        component: Write
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition //游览器返回记住位置
        } else {
            return {
                x: 0,
                y: 0
            } //滚动到顶
        }
    }
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}

export default router