<template>
  <Main>
    <div class="doctor" slot="main_content">
      <img
        v-if="!isMobile"
        class="product_img"
        src="../../assets/banner3.jpg"
        alt=""
      />
      <img
        v-else
        class="product_img"
        src="../../assets/phone/banner3.jpg"
        alt=""
      />

      <!-- 产品介绍 -->
      <div class="doctor_introduce">
        <div class="doctor_title">
          <div>产品介绍</div>
          <img src="../../assets/blue_point.png" alt="" />
        </div>
        <div class="doctor_introduce_list">
          <div class="doctor_introduce_item">
            <div class="doctor_introduce_item_cont">
              <div class="doctor_introduce_title">医患绑定，全病程跟踪</div>
              <div class="doctor_introduce_desc">
                医生与患者之间建立永久绑定关系，方便医生对患者进行全病程监测、全面掌握患者病情并及时响应，提高患者的诊后生活质量，同时帮助医生进行信息整合和分析，提供可视化的医疗数据展示，并实现多学科的协作。为患者提供更加个性化、连续和有效的医疗服务。
              </div>
              <div
                class="doctor_introduce_btn"
                @click="$store.commit('isShowChatStatus', true)"
              >
                了解详情
              </div>
            </div>
            <img
              class="doctor_introduce_item_img doctor_introduce_item_left doctor_introduce_item_img1"
              src="../../assets/doctor1.png"
              alt=""
            />
          </div>
          <div class="doctor_introduce_item">
            <img
              v-if="!isMobile"
              class="doctor_introduce_item_img doctor_introduce_item_right doctor_introduce_item_img2"
              src="../../assets/doctor2.png"
              alt=""
            />
            <div class="doctor_introduce_item_cont">
              <div class="doctor_introduce_title">病程同步，全息视图展示</div>
              <div class="doctor_introduce_desc">
                符合医生需求的标准化病程记录：就诊医院、诊断结果、个体信息时间、诊疗手段等。直观展示病人的动态信息，包括量表评估记录、用药记录、病情变化，便于医生进行随访、治疗方案调整和科研数据分析。
              </div>
              <div
                class="doctor_introduce_btn"
                @click="$store.commit('isShowChatStatus', true)"
              >
                了解详情
              </div>
            </div>
            <img
              v-if="isMobile"
              class="doctor_introduce_item_img doctor_introduce_item_right doctor_introduce_item_img2"
              src="../../assets/doctor2.png"
              alt=""
            />
          </div>
          <div class="doctor_introduce_item">
            <div class="doctor_introduce_item_cont">
              <div class="doctor_introduce_title">诊前评估，提高问诊效率</div>
              <div class="doctor_introduce_desc">
                评估前置化，通过诊前问卷和量表评估等信息，减少基本信息的沟通，信息存档、同步，引入电子病历系统和电子处方系统，实现医生和患者之间的无纸化沟通和数据共享，医生可随时查看病人信息，便于了解患者就诊信息和病情，结合问诊内容制定过敏治疗方案，极大地提高问诊效率。
              </div>
              <div
                class="doctor_introduce_btn"
                @click="$store.commit('isShowChatStatus', true)"
              >
                了解详情
              </div>
            </div>
            <img
              class="doctor_introduce_item_img doctor_introduce_item_left doctor_introduce_item_img3"
              src="../../assets/doctor3.png"
              alt=""
            />
          </div>
          <div class="doctor_introduce_item">
            <div
              v-if="!isMobile"
              id="doctor"
              class="doctor_introduce_item_img doctor_introduce_item_right doctor_introduce_item_group doctor_introduce_item_img4"
            >
              <img
                class="doctor_introduce_item_group_img"
                src="../../assets/doctor4.png"
                alt=""
              />
              <div class="doctor_introduce_item_group_list">
                <div
                  class="doctor_introduce_item_group_txt doctor_introduce_item_group_txt1"
                >
                  <div class="doctor_introduce_item_group_txt_cont">脱敏类</div>
                </div>
                <div
                  class="doctor_introduce_item_group_txt doctor_introduce_item_group_txt2"
                >
                  <div class="doctor_introduce_item_group_txt_cont">皮肤类</div>
                </div>
                <div
                  class="doctor_introduce_item_group_txt doctor_introduce_item_group_txt3"
                >
                  <div class="doctor_introduce_item_group_txt_cont">耳鼻喉</div>
                </div>
                <div
                  class="doctor_introduce_item_group_txt doctor_introduce_item_group_txt4"
                >
                  <div class="doctor_introduce_item_group_txt_cont">呼吸类</div>
                </div>
                <div
                  class="doctor_introduce_item_group_txt doctor_introduce_item_group_txt5"
                >
                  <div class="doctor_introduce_item_group_txt_cont">
                    <div></div>
                    <div style="margin: 0 9px"></div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="doctor_introduce_item_cont">
              <div class="doctor_introduce_title">自主分组，专项高效沟通</div>
              <div class="doctor_introduce_desc">
                建立细分患者数据库，满足医生便捷、高效沟通的需求。医生可根据分类制定相应的沟通策略，对不同类型的患者进行专项高效的沟通，提高医疗效果和满意度。
              </div>
              <div
                class="doctor_introduce_btn"
                @click="$store.commit('isShowChatStatus', true)"
              >
                了解详情
              </div>
            </div>
            <div
              v-if="isMobile"
              id="doctor"
              class="doctor_introduce_item_img doctor_introduce_item_right doctor_introduce_item_group doctor_introduce_item_img4"
            >
              <img
                class="doctor_introduce_item_group_img"
                src="../../assets/doctor4.png"
                alt=""
              />
              <div class="doctor_introduce_item_group_list">
                <div
                  class="doctor_introduce_item_group_txt doctor_introduce_item_group_txt1"
                >
                  <div class="doctor_introduce_item_group_txt_cont">脱敏类</div>
                </div>
                <div
                  class="doctor_introduce_item_group_txt doctor_introduce_item_group_txt2"
                >
                  <div class="doctor_introduce_item_group_txt_cont">皮肤类</div>
                </div>
                <div
                  class="doctor_introduce_item_group_txt doctor_introduce_item_group_txt3"
                >
                  <div class="doctor_introduce_item_group_txt_cont">耳鼻喉</div>
                </div>
                <div
                  class="doctor_introduce_item_group_txt doctor_introduce_item_group_txt4"
                >
                  <div class="doctor_introduce_item_group_txt_cont">呼吸类</div>
                </div>
                <div
                  class="doctor_introduce_item_group_txt doctor_introduce_item_group_txt5"
                >
                  <div class="doctor_introduce_item_group_txt_cont">
                    <div></div>
                    <div style="margin: 0 9px"></div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 产品优势 -->
      <div class="doctor_superiority">
        <div class="doctor_title">
          <div>产品优势</div>
          <img src="../../assets/blue_point.png" alt="" />
        </div>
        <img
          class="doctor_superiority_bg"
          src="../../assets/left_bottom_pointer.png"
          alt=""
        />
        <div class="doctor_superiority_list">
          <div class="doctor_superiority_item">
            <img
              v-if="!isMobile"
              class="doctor_superiority_item_img"
              src="../../assets/superiority5.png"
              alt=""
            />
            <img
              v-else
              class="doctor_superiority_item_img"
              src="../../assets/phone/superiority5.png"
              alt=""
            />
            <div class="doctor_superiority_item_cont">
              <div class="doctor_superiority_item_title">赋能患者管理</div>
              <div class="doctor_superiority_item_desc">
                数字化工具让管理更加高效，提高医疗质量和患者满意度。节省医生的精力，助力医疗行业的发展。
              </div>
            </div>
          </div>
          <div class="doctor_superiority_item">
            <img
              v-if="!isMobile"
              class="doctor_superiority_item_img"
              src="../../assets/superiority6.png"
              alt=""
            />
            <img
              v-else
              class="doctor_superiority_item_img"
              src="../../assets/phone/superiority6.png"
              alt=""
            />
            <div class="doctor_superiority_item_cont">
              <div class="doctor_superiority_item_title">提高技能和收入</div>
              <div class="doctor_superiority_item_desc">
                拓宽医生的学习圈子，充分调动医生的积极性，发挥专业价值，增加医生群体收入。
              </div>
            </div>
          </div>
          <div class="doctor_superiority_item">
            <img
              v-if="!isMobile"
              class="doctor_superiority_item_img"
              src="../../assets/superiority7.png"
              alt=""
            />
            <img
              v-else
              class="doctor_superiority_item_img"
              src="../../assets/phone/superiority7.png"
              alt=""
            />
            <div class="doctor_superiority_item_cont">
              <div class="doctor_superiority_item_title">拓展医患互动</div>
              <div class="doctor_superiority_item_desc">
                将患者和医院专家、医护进行链接，提高患者的依从性，建立更强的医患关系。
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 社会价值 -->
      <div class="doctor_value" v-if="!isMobile">
        <div class="doctor_title">
          <div>社会价值</div>
          <img src="../../assets/blue_point.png" alt="" />
        </div>
        <div class="doctor_value_cont">
          <div class="doctor_value_left">
            <img
              v-if="society == 1"
              class="doctor_value_left_img"
              src="../../assets/society4.png"
              alt=""
            />
            <img
              v-if="society == 2"
              class="doctor_value_left_img"
              src="../../assets/society5.png"
              alt=""
            />
            <img
              v-if="society == 3"
              class="doctor_value_left_img"
              src="../../assets/society6.png"
              alt=""
            />
            <div class="doctor_value_left_cont">
              <div class="doctor_value_left_title">
                {{
                  society == 1
                    ? "扩大覆盖范围"
                    : society == 2
                    ? "改善资源分配"
                    : society == 3
                    ? "减少人才流失"
                    : ""
                }}
              </div>
              <div class="doctor_value_left_list">
                <div class="doctor_value_left_item">
                  <div class="doctor_value_left_item_diamond">
                    <div></div>
                    <div></div>
                  </div>
                  <div class="doctor_value_left_item_desc">
                    {{
                      society == 1
                        ? "消除地域限制。让偏远地区或医疗资源匮乏地方的患者也能够获得高质量的医疗服务。"
                        : society == 2
                        ? "分担传统医疗机构的压力，减轻医院的门急诊负荷；"
                        : society == 3
                        ? "调动医务人员的积极性与创造性，充分发挥医务人员的主观能动性；"
                        : ""
                    }}
                  </div>
                </div>
                <div class="doctor_value_left_item">
                  <div
                    class="doctor_value_left_item_diamond"
                    v-if="society != 1"
                  >
                    <div></div>
                    <div></div>
                  </div>
                  <div class="doctor_value_left_item_desc">
                    {{
                      society == 1
                        ? ""
                        : society == 2
                        ? "患者可以在线上咨询疾病问题，减少非紧急性疾病医疗资源占用问题。"
                        : society == 3
                        ? "消除医护人员对患者的不信任，减少医学人才的流失。"
                        : ""
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="doctor_value_right">
            <div
              :class="[
                'doctor_value_right_item',
                society == 1 ? 'doctor_value_right_item_active' : '',
              ]"
              @click="society = 1"
            >
              扩大覆盖范围
            </div>
            <div
              :class="[
                'doctor_value_right_item',
                society == 2 ? 'doctor_value_right_item_active' : '',
              ]"
              @click="society = 2"
            >
              改善资源分配
            </div>
            <div
              :class="[
                'doctor_value_right_item',
                society == 3 ? 'doctor_value_right_item_active' : '',
              ]"
              @click="society = 3"
            >
              减少人才流失
            </div>
          </div>
        </div>
      </div>

      <div class="doctor_value" v-else>
        <div class="doctor_title">
          <div>社会价值</div>
          <img src="../../assets/blue_point.png" alt="" />
        </div>
        <div class="doctor_value_cont">
          <div class="doctor_value_item">
            <img
              class="doctor_value_left_img"
              src="../../assets/phone/society4.png"
              alt=""
            />
            <div class="doctor_value_left_cont">
              <div class="doctor_value_left_title">扩大覆盖范围</div>
              <div class="doctor_value_left_list">
                <div class="doctor_value_left_item">
                  <div class="doctor_value_left_item_diamond">
                    <div></div>
                    <div></div>
                  </div>
                  <div class="doctor_value_left_item_desc">
                    消除地域限制。让偏远地区或医疗资源匮乏地方的患者也能够获得高质量的医疗服务。
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="doctor_value_item">
            <img
              class="doctor_value_left_img"
              src="../../assets/phone/society5.png"
              alt=""
            />
            <div class="doctor_value_left_cont">
              <div class="doctor_value_left_title">改善资源分配</div>
              <div class="doctor_value_left_list">
                <div class="doctor_value_left_item">
                  <div class="doctor_value_left_item_diamond">
                    <div></div>
                    <div></div>
                  </div>
                  <div class="doctor_value_left_item_desc">
                    分担传统医疗机构的压力，减轻医院的门急诊负荷；
                  </div>
                </div>
                <div class="doctor_value_left_item">
                  <div class="doctor_value_left_item_diamond">
                    <div></div>
                    <div></div>
                  </div>
                  <div class="doctor_value_left_item_desc">
                    患者可以在线上咨询疾病问题，减少非紧急性疾病医疗资源占用问题。
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="doctor_value_item">
            <img
              class="doctor_value_left_img"
              src="../../assets/phone/society6.png"
              alt=""
            />
            <div class="doctor_value_left_cont">
              <div class="doctor_value_left_title">减少人才流失</div>
              <div class="doctor_value_left_list">
                <div class="doctor_value_left_item">
                  <div class="doctor_value_left_item_diamond">
                    <div></div>
                    <div></div>
                  </div>
                  <div class="doctor_value_left_item_desc">
                    调动医务人员的积极性与创造性，充分发挥医务人员的主观能动性；
                  </div>
                </div>
                <div class="doctor_value_left_item">
                  <div class="doctor_value_left_item_diamond">
                    <div></div>
                    <div></div>
                  </div>
                  <div class="doctor_value_left_item_desc">
                    消除医护人员对患者的不信任，减少医学人才的流失。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Main>
</template>

<script>
import computed from "./src/computed"
import watch from "./src/watch"
import Main from "../../components/Main"
import anime from "animejs"

export default {
  name: "doctor",
  data () {
    return {
      society: 1,
      isMobile: false,
    }
  },
  components: {
    Main,
  },
  watch,
  methods: {
    showAnime (typeArr) {
      let arr = []
      let _this = this
      typeArr.forEach((el) => {
        console.log(document.querySelector(`#${el}`))
        if (!document.querySelector(`#${el}`)) return
        let obj = {
          el: `#${el}`,
          loop: false, //是否循环使用 true/false 设置false后执行执行一次后会销毁
          type: "all", //触发方式 支持up-down down-up all三种方式
          func: function () {
            anime({
              targets: `#${el} .doctor_introduce_item_group_img`,
              scale: [0, 1],
              opacity: [0, 1],
              delay: 0,
            })
            document
              .querySelectorAll(`#${el} .doctor_introduce_item_group_txt`)
              .forEach((item, index) => {
                anime({
                  targets:
                    `#${el} .doctor_introduce_item_group_txt` + (index + 1),
                  scale: [0, 1],
                  opacity: [0, 1],
                  delay: (index + 1) * 150,
                })
              })
          },
        }
        arr.push(obj)
      })

      new MyScroll({
        event: arr,
      })
    },
    isMobi () {
      if (window.innerWidth < 769) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    },
  },
  computed,
  created () {
    window.addEventListener("resize", this.isMobi)
    this.isMobi()
    this.$nextTick(() => {
      this.showAnime(["doctor"])
    })
  },
};
</script>

<style lang="scss" scoped>
@import "./css/index.scss";
@import "./css/doctor.scss";
</style>
