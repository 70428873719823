<template>
  <div class="main">
    <!-- 顶部导航 -->
    <div class="main_nav_content">
      <div class="main_nav">
        <img class="main_nav_logo" src="../assets/logo.png" alt="" />
        <div class="main_nav_cont">
          <el-menu
            :default-active="activeIndex"
            mode="horizontal"
            text-color="#282d3c"
            active-text-color="#3076fd"
            router
          >
            <el-menu-item index="/">首页</el-menu-item>
            <el-submenu index="/product" popper-class="nav_two">
              <template slot="title">产品中心</template>
              <el-menu-item index="/product/patient">患者服务</el-menu-item>
              <el-menu-item index="/product/doctor">医生服务</el-menu-item>
              <el-menu-item index="/product/saas">科室服务</el-menu-item>
              <el-menu-item index="/product/operate">自营产品</el-menu-item>
              <el-menu-item index="/product/drug">医疗药品</el-menu-item>
              <el-menu-item index="/product/apparatus">医疗器械</el-menu-item>
            </el-submenu>
            <el-menu-item index="/figure">数字医生</el-menu-item>
            <el-menu-item index="/news">新闻中心</el-menu-item>
            <el-menu-item index="/sensitive">了解过敏</el-menu-item>
            <!-- <el-menu-item index="/foundation">中初保基金会</el-menu-item> -->
            <el-menu-item index="/introduce">关于我们</el-menu-item>
          </el-menu>
        </div>
        <div @click="drawer = true" class="show_more_menu">
          <img src="../assets/more_menu_gray.png" alt="" />
        </div>
      </div>
    </div>
    <el-drawer :visible.sync="drawer" :with-header="false">
      <div class="small_show_menu">
        <el-menu
          :default-active="activeIndex"
          mode="vertical"
          menu-trigger="click"
          text-color="#282d3c"
          active-text-color="#3076fd"
          router
        >
          <el-menu-item index="/">首页</el-menu-item>
          <el-submenu index="/product" popper-class="nav_two">
            <template slot="title">产品中心</template>
            <el-menu-item index="/product/patient">患者服务</el-menu-item>
            <el-menu-item index="/product/doctor">医生服务</el-menu-item>
            <el-menu-item index="/product/saas">科室服务</el-menu-item>
            <el-menu-item index="/product/operate">自营产品</el-menu-item>
            <el-menu-item index="/product/drug">医疗药品</el-menu-item>
            <el-menu-item index="/product/apparatus">医疗器械</el-menu-item>
          </el-submenu>
          <el-menu-item index="/figure">数字医生</el-menu-item>
          <el-menu-item index="/news">新闻中心</el-menu-item>
          <el-menu-item index="/sensitive">了解过敏</el-menu-item>
          <!-- <el-menu-item index="/foundation">中初保基金会</el-menu-item> -->
          <el-menu-item index="/introduce">关于我们</el-menu-item>
        </el-menu>
      </div>
    </el-drawer>
    <div class="h84"></div>
  </div>
</template>

<script>
export default {
  name: "Nav",
  data () {
    return {
      activeIndex: '/',
      drawer: false
    }
  },
  created () {
    this.activeIndex = this.$route.path
  }
};
</script>

<style lang="scss" scoped>
@import "../common.scss";
.h84 {
  height: 84px;
  @include respond-to("phone") {
    height: 50px;
  }
}

// 顶部导航
::v-deep .el-drawer {
  @include respond-to("pad") {
    width: 30% !important;
  }
  @include respond-to("phone") {
    width: 50% !important;
  }
}

::v-deep .el-drawer__wrapper {
  z-index: 1000000 !important;
}

.main_nav_content {
  padding: 0 2em;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  z-index: 999999;
}

.main_nav {
  max-width: 1200px;
  height: 84px;
  @include respond-to("phone") {
    height: 50px;
  }
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main_nav_logo {
  width: 188px;
  height: 41px;
  @include respond-to("phone") {
    width: 132px;
    height: 30px;
  }
}

.main_nav_cont {
  display: flex;
  align-items: center;
  @include respond-to("is_nav") {
    display: none;
  }
}

.show_more_menu {
  display: none;
  @include respond-to("is_nav") {
    display: block;
  }
}

// 导航栏一级样式
::v-deep .el-menu::after {
  display: none !important;
}

::v-deep .el-menu.el-menu--horizontal {
  border-bottom: none !important;
}

::v-deep .el-menu--horizontal > .el-menu-item,
::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
  border-bottom: none !important;
  height: 84px;
  line-height: 84px;
  @include respond-to("phone") {
    height: 50px;
    line-height: 50px;
  }
}

::v-deep .el-menu li {
  margin-right: 46px;
  &:last-child {
    margin-right: 0;
  }
  @include respond-to("is_nav") {
    margin-right: 0;
  }
}

::v-deep .el-submenu li {
  @include respond-to("phone") {
    background-color: #f5f5fa;
    border-bottom: none !important;
    font-size: 14px;
    font-weight: 400;
    color: #666973;
  }
}

::v-deep .el-dropdown-menu__item,
::v-deep .el-menu-item,
::v-deep .el-submenu__title {
  font-size: 18px;
  font-weight: 400;
  color: #282d3c;
  padding: 0;
  box-sizing: border-box;
  @include respond-to("phone") {
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid #d2d7e1;
  }
}

::v-deep .is-active {
  font-weight: 700;
}

::v-deep .el-submenu__title i {
  color: #c4c9d8;
}

::v-deep .el-submenu__icon-arrow {
  font-size: 16px;
}

::v-deep .is-opened .el-submenu__title i {
  color: #3076fd;
}
</style>
