<template>
  <Main>
    <div class="product" slot="main_content"></div>
  </Main>
</template>

<script>
import computed from "./src/computed"
import methods from "./src/methods"
import watch from "./src/watch"
import Main from "../../components/Main"

export default {
  name: "product",
  data () {
    return {

    }
  },
  components: {
    Main,
  },
  watch,
  methods,
  computed,
  created () {

  },
};
</script>

<style lang="scss" scoped>
@import "./css/index.scss";
</style>
