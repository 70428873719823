<template>
  <div class="chat">
    <el-dialog
      class="vertical-center-modal"
      :width="isMobile ? '90%' : '50%'"
      title="小敏正在为您服务"
      :visible.sync="isShowChat"
      :close-on-click-modal="false"
      @close="$emit('close')"
    >
      <!-- 聊天列表 -->
      <div class="chat_list" ref="chat_list">
        <div
          :class="[
            'chat_item',
            item.position == 'left'
              ? 'chat_item_left'
              : item.position == 'right'
              ? 'chat_item_right'
              : '',
          ]"
          v-for="(item, index) in arrList"
          :key="index"
        >
          <img
            class="chat_item_avatar"
            v-if="item.position == 'left'"
            src="../assets/chat.png"
            alt=""
          />
          <div class="chat_item_cont">
            <div
              :class="[
                'chat_item_title',
                item.position == 'left'
                  ? 'chat_item_title_left'
                  : item.position == 'right'
                  ? 'chat_item_title_right'
                  : '',
              ]"
            >
              {{ item.realname }} {{ item.time }}
            </div>
            <div
              :class="[
                'chat_item_txt',
                item.position == 'left'
                  ? 'chat_item_left'
                  : item.position == 'right'
                  ? 'chat_item_right'
                  : '',
              ]"
            >
              <div
                :class="[
                  'chat_item_text',
                  item.position == 'left'
                    ? 'chat_item_text_left'
                    : item.position == 'right'
                    ? 'chat_item_text_right'
                    : '',
                ]"
              >
                {{ item.content }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 编辑信息 -->
      <div class="chat_edit">
        <el-input
          type="textarea"
          placeholder="请在此输入..."
          v-model="ins.content"
        ></el-input>
        <el-button type="primary" @click="submit">发 送</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { post } from '../http/http'

export default {
  name: "Chat",
  data () {
    return {
      arrList: [],
      ins: {
        fromid: '',
        toid: '',
        content: '',
        objectname: 'text',
        type: 'connect',
        key: '',
        source: 'imgtext'
      },
      isMobile: false
    }
  },
  watch: {
    isShowChat (val) {
      if (val) {
        this.initWebSocket()
      }
    }
  },
  computed: {
    isShowChat: {
      get () {
        return this.$store.state.isShowChat
      },
      set (val) {
        this.$store.commit('isShowChatStatus', val)
      }
    }
  },
  methods: {
    //初始化weosocket
    initWebSocket () {
      this.websock = new WebSocket("wss://xcx.hzszyjyl.com/wes")
      this.websock.onmessage = this.websocketonmessage
      this.websock.onopen = this.websocketonopen
      this.websock.onerror = this.websocketonerror
      this.websock.onclose = this.websocketclose
    },
    //连接建立之后执行send方法发送数据
    websocketonopen () {
      this.websocketsend(JSON.stringify(this.ins))
      // 发送信息后ins中type类型由 connect(连接) 改为 message(消息)
      this.ins.type = 'message'
    },
    //连接建立失败重连
    websocketonerror () {
      this.initWebSocket()
    },
    //数据接收
    websocketonmessage (e) {
      const redata = JSON.parse(e.data)
      if (redata.position == 'left') {
        this.arrList.push(redata)
      }
      this.$nextTick(() => {
        if (this.$refs.chat_list) {
          this.$refs.chat_list.scrollTo({
            top: this.$refs.chat_list.scrollHeight,
            behavior: 'smooth'
          })
        }
      })
    },
    //数据发送
    websocketsend (Data) {
      this.websock.send(Data)
    },
    // 断开连接
    websocketclose (e) {
      // console.log('断开连接')
    },
    // 年月日时分秒
    getCurrentTime () {
      var date = new Date()
      let yy = date.getFullYear()
      let mm = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
      let dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      let hh = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      let mf = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      let ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return `${yy}-${mm}-${dd} ${hh}:${mf}:${ss}`
    },
    // 发送
    submit () {
      if (this.ins.content.trim() == '') {
        this.ins.content = ''
        this.$message.error('请输入内容')
        return
      }
      this.websocketsend(JSON.stringify(this.ins))
      var redata = {
        position: 'right',
        realname: '',
        content: this.ins.content,
        time: this.getCurrentTime()
      }
      this.arrList.push(redata)
      this.ins.content = ''
    },
    isMobi () {
      if (window.innerWidth < 1000) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    },
  },
  destroyed () {
    // 离开路由之后断开websocket连接
    this.websocketclose()
  },
  created () {
    post(this.$api.public.getChatIds).then((res) => {
      if (res.code == 200) {
        this.ins.fromid = res.data.userid
        this.ins.toid = res.data.doctorid
      } else {
        this.$message.error(res.msg)
      }
    }).catch((err) => {
      this.$message.error(err.msg)
    })
  },
  mounted () {
    this.$nextTick(() => {
      this.isMobi()
    })
    window.addEventListener("resize", this.isMobi)
  }
};
</script>

<style lang="scss" scoped>
@import "../common.scss";

// 弹窗居中
.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

// 弹窗层级
::v-deep .el-dialog__wrapper {
  z-index: 1000000 !important;
  background-color: rgba(0, 0, 0, 0.3);
}

// 弹窗样式
::v-deep .el-dialog {
  margin: 0 !important;
  border-radius: 10px;
  box-shadow: 0 0 6px 0 rgba(48, 118, 253, 0.2);
  @include respond-to("pad") {
    box-shadow: 0 0 5px 0 rgba(48, 118, 253, 0.2);
  }
  @include respond-to("phone") {
    box-shadow: 0 0 3px 0 rgba(48, 118, 253, 0.2);
  }
}

// 弹窗头部样式
::v-deep .el-dialog__header {
  background-color: #3076fd;
  border-radius: 10px 10px 0 0;
  padding: 15px 30px;
  box-sizing: border-box;
  @include respond-to("pad") {
    padding: 12px 20px;
  }
  @include respond-to("phone") {
    padding: 9px 10px;
  }
}

::v-deep .el-dialog__title {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  line-height: 29px;
  @include respond-to("pad") {
    font-size: 16px;
    line-height: 23px;
  }
  @include respond-to("phone") {
    font-size: 12px;
    line-height: 17px;
  }
}

// 弹窗关闭按钮
::v-deep .el-dialog__headerbtn {
  top: 14px;
  right: 30px;
  @include respond-to("pad") {
    top: 12px;
    right: 20px;
  }
  @include respond-to("phone") {
    top: 9px;
    right: 10px;
  }
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  font-size: 32px;
  color: #fff;
  @include respond-to("pad") {
    font-size: 26px;
  }
  @include respond-to("phone") {
    font-size: 18px;
  }
  &:hover {
    color: #fff !important;
  }
}

// 弹窗内容
::v-deep .el-dialog__body {
  height: 70vh;
  padding: 20px 30px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @include respond-to("pad") {
    padding: 20px;
  }
  @include respond-to("phone") {
    padding: 14px;
  }
}

// 弹窗滚动条
::v-deep .el-dialog__body {
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #e6e6e6;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
    box-shadow: none;
  }
  ::-webkit-scrollbar-track {
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.06);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  }
}

// 聊天列表
.chat_list {
  flex: 1;
  height: 0;
  overflow-y: auto;
  padding-right: 4px;
  padding-bottom: 20px;
  box-sizing: border-box;
}

.chat_item {
  display: flex;
  margin-bottom: 36px;
  @include respond-to("pad") {
    margin-bottom: 20px;
  }
  @include respond-to("phone") {
    margin-bottom: 10px;
  }
}

.chat_list > .chat_item:last-child {
  margin-bottom: 0;
}

.chat_item_left {
  justify-content: flex-start;
}

.chat_item_right {
  justify-content: flex-end;
}

.chat_item_cont {
  max-width: 60%;
}

.chat_item_avatar {
  width: 60px;
  height: 60px;
  display: block;
  margin-right: 10px;
  @include respond-to("pad") {
    width: 48px;
    height: 48px;
  }
  @include respond-to("phone") {
    width: 36px;
    height: 36px;
  }
}

.chat_item_title {
  font-size: 14px;
  font-weight: 400;
  color: #969baa;
  line-height: 20px;
  @include respond-to("pad") {
    font-size: 12px;
    line-height: 17px;
  }
  @include respond-to("phone") {
    font-size: 10px;
    line-height: 15px;
  }
}

.chat_item_title_left {
  text-align: left;
}

.chat_item_title_right {
  text-align: right;
}

.chat_item_txt {
  margin-top: 10px;
  display: flex;
  @include respond-to("pad") {
    margin-top: 8px;
  }
  @include respond-to("phone") {
    margin-top: 6px;
  }
}

.chat_item_text {
  padding: 12px 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  @include respond-to("pad") {
    padding: 9px 10px;
    font-size: 14px;
    line-height: 20px;
  }
  @include respond-to("phone") {
    padding: 5px 9px;
    font-size: 12px;
    line-height: 18px;
  }
}

.chat_item_text_left {
  color: #282d3c;
  background-color: #f5f5fa;
  border-radius: 0 10px 10px 10px;
}

.chat_item_text_right {
  color: #3076fd;
  background-color: #d6e4ff;
  border-radius: 10px 0 10px 10px;
}

// 编辑信息
.chat_edit {
  height: 200px;
  border-top: 1px solid #d2d7e1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  @include respond-to("pad") {
    height: 160px;
  }
  @include respond-to("phone") {
    height: 130px;
  }
}

::v-deep .el-textarea {
  flex: 1;
}

::v-deep .el-textarea__inner {
  height: 100%;
  border: none;
  resize: none;
  padding: 20px 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
  color: #282d3c;
  line-height: 23px;
  @include respond-to("pad") {
    padding: 16px 0;
    font-size: 14px;
    line-height: 20px;
  }
  @include respond-to("phone") {
    padding: 10px 0;
    font-size: 12px;
    line-height: 18px;
  }
}

::v-deep .el-button {
  margin-top: 20px;
  @include respond-to("phone") {
    margin-top: 10px;
  }
}

::v-deep .el-button--primary {
  background-color: #3076fd;
}
</style>
