/**
 * 属性值监控
 * 1、不能用()=>{}  箭头函数定义
 */
export default {
    ruleForm: {
        handler(val) {
            localStorage.setItem('ruleForm', JSON.stringify(val))
        },
        deep: true
    }
};