/**
 * 常规方法定义
 * 1、不能用()=>{}  箭头函数定义
 * 2、大量计算方法建议放computed
 */
import { post } from "../../../http/http";

export default {
  isMobi() {
    if (window.innerWidth < 769) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  getData() {
    post(this.$api.introducePage.getData, {})
      .then((res) => {
        if (res.code == 200) {
          this.objData = res.data;
        } else {
          this.$message.error(res.msg);
        }
      })
      .catch((err) => {
        this.$message.error(err.msg);
      });
  },
  infoWindowClose() {
    this.show = false;
  },
  infoWindowOpen() {
    this.show = true;
  },
};
