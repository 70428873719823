/**
 * 常规方法定义
 * 1、不能用()=>{}  箭头函数定义
 * 2、大量计算方法建议放computed
 */
import anime from "animejs"

export default {
  isMobi () {
    if (window.innerWidth < 769) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }

    if (window.innerWidth > 768 && window.innerWidth < 1080) {
      this.isPhone = true
    } else {
      this.isPhone = false
    }
  },
  showAnime (typeArr) {
    let arr = []
    let _this = this
    let objType = {
      figure: 'figure_model_item',
      figure1: 'figure1_model_item',
      ai: 'figure_introduce_ai',
      gpt: 'figure_introduce_gpt'
    }
    let objType1 = {
      figure: 'figure_model_bg',
      figure1: 'figure_model_bg',
      ai: 'figure_introduce_item_img1_ai',
      gpt: 'figure_introduce_item_img2_cont4'
    }
    typeArr.forEach((el) => {
      if (!document.querySelector(`#${el}`)) return
      let obj = {
        el: document.querySelector(`#${el}`),
        loop: false, //是否循环使用 true/false 设置false后执行执行一次后会销毁
        type: "all", //触发方式 支持up-down down-up all三种方式
        func: function () {
          anime({
            targets: `.${objType1[el]}`,
            scale: [0, 1],
            opacity: [0, 1],
            duration: 3000,
            delay: 0,
          })
          document
            .querySelectorAll(`#${el} .${objType[el]}`)
            .forEach((item, index) => {
              anime({
                targets: `#${el} .${objType[el]}` + (index + 1),
                scale: [0, 1],
                opacity: [0, 1],
                delay: (index + 1) * 200,
              })
            })
        },
      }
      arr.push(obj)
    })

    new MyScroll({
      event: arr,
    })
  }
}
