<template>
  <Main>
    <div class="saas" slot="main_content">
      <img
        v-if="!isMobile"
        class="product_img"
        src="../../assets/banner4.jpg"
        alt=""
      />
      <img
        v-else
        class="product_img"
        src="../../assets/phone/banner4.jpg"
        alt=""
      />
      <div class="saas_cont">
        <!-- 产品介绍 -->
        <div class="flex jcc aic">
          <div class="cont_title">
            <div class="cont_title_txt">
              <div>产品介绍</div>
            </div>
            <div class="cont_title_tip">
              <img
                src="../../assets/blue_point.png"
                alt=""
                class="blue_pointer"
              />
            </div>
          </div>
        </div>
        <div class="list">
          <div class="item">
            <div class="img_cont">
              <img src="../../assets/saas1.png" alt="" />
            </div>
            <div class="item_cont_container">
              <div class="item_title">科室业务管理系统</div>
              <div class="item_text">
                稳步推进科室工作、数据直观展示、医嘱、病例模板按需调整、高效推动业务进展。数据看板帮助科室管理和监控业务数据，提高业务运营效率、优化资源配置；方便科室进行医护人员排班以及权限管理，使科室的医疗服务能够持续高效运作。
              </div>
              <div
                class="button"
                @click="$store.commit('isShowChatStatus', true)"
              >
                了解详情
              </div>
            </div>
          </div>
          <div class="item">
            <div class="img_cont">
              <img src="../../assets/saas2.png" alt="" />
            </div>
            <div class="item_cont_container">
              <div class="item_title">诊疗流程系统</div>
              <div class="item_text">
                记录和管理患者的基本信息、病历、检查结果等，便于医务人员查看患者历史和做出准确的诊断和治疗决策；支持医务人员接诊患者，并提供相关辅助功能，帮助医务人员做出准确的诊断。为医疗机构提供诊疗全流程服务和全面管理的支持，优化医疗服务流程，提高工作效率，改善患者就诊体验。
              </div>
              <div
                class="button"
                @click="$store.commit('isShowChatStatus', true)"
              >
                了解详情
              </div>
            </div>
          </div>
          <div class="item">
            <div class="img_cont flex aic jcc">
              <div class="big_circle" id="eva_system">
                <div class="big_dashed_circle"></div>
                <div class="animation animation1">
                  <img
                    class="eva_icon"
                    src="../../assets/eva_icon1.png"
                    alt=""
                  />
                  <div class="eva_name">GMTZZJ</div>
                </div>
                <div class="animation animation2">
                  <img
                    class="eva_icon"
                    src="../../assets/eva_icon2.png"
                    alt=""
                  />
                  <div class="eva_name">药物过敏</div>
                </div>
                <div class="animation animation3">
                  <img
                    class="eva_icon"
                    src="../../assets/eva_icon3.png"
                    alt=""
                  />
                  <div class="eva_name">ACT</div>
                </div>
                <div class="animation animation4">
                  <img
                    class="eva_icon"
                    src="../../assets/eva_icon4.png"
                    alt=""
                  />
                  <div class="eva_name">AMZSC</div>
                </div>
                <div class="animation animation5">
                  <img
                    class="eva_icon"
                    src="../../assets/eva_icon5.png"
                    alt=""
                  />
                  <div class="eva_name">TNSS</div>
                </div>
                <div class="bg_img">
                  <img src="../../assets/eva_bg_img.png" alt="" />
                </div>
                <div class="light_blue_circle">
                  <div class="translucent_blue_circle">
                    <div class="blue_circle">量表评估</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="item_cont_container">
              <div class="item_title">量表评估系统</div>
              <div class="item_text">
                集成舌面图像分析、脉诊数据采集、耳穴/为医生提供了一个方便、高效的工具，用于进行精确、全面的患者评估。医患交互，医生可实时查看表评估结果、使用评估量表对患者进行评估，对评估结果数据化、规范化储存，随时调取所需信息辅助诊疗。减少纸质量表的使用和管理问题，还提供了更多的数据分析和个性化评估的可能性，促进更好的医患交流和治疗决策。
              </div>
              <div
                class="button"
                @click="$store.commit('isShowChatStatus', true)"
              >
                了解详情
              </div>
            </div>
          </div>
          <div class="item">
            <div class="img_cont">
              <img src="../../assets/saas3.png" alt="" />
            </div>
            <div class="item_cont_container">
              <div class="item_title">电子医嘱系统</div>
              <div class="item_text">
                简化问诊流程，提高医生开具处方的效率、提高患者依从性，缩短就诊时间，推动诊疗流程的合理化进行。通过电子终端设备快速输入、修改和查询医嘱信息，避免了纸质医嘱的繁琐操作和管理；实时传递给相关人员，减少信息传递的延迟和错误；对医嘱的执行情况进行实时监控和提醒，确保医嘱的准时执行和用药的合理性。
              </div>
              <div
                class="button"
                @click="$store.commit('isShowChatStatus', true)"
              >
                了解详情
              </div>
            </div>
          </div>
          <div class="item">
            <div class="img_cont">
              <img src="../../assets/saas4.png" alt="" />
            </div>
            <div class="item_cont_container">
              <div class="item_title">脱敏管理系统</div>
              <div class="item_text">
                <div class="item_text_small_p">
                  <span class="sub_text_small_title">皮下脱敏：</span
                  >结合脱敏治疗制定脱敏方案，患者治疗时，系统自动推荐本次治疗的所有相关信息。减少患者购药时间，智能提醒，降低患者脱落率，提高脱敏治疗效果。
                </div>
                <div class="item_text_small_p">
                  <span class="sub_text_small_title">舌下脱敏：</span
                  >检测患者居家治疗记录，提供患者治疗方案，医护人员可对患者脱敏治疗进行监控，及时发现并处理患者异常情况，同时调整患者的治疗方案。
                </div>
              </div>
              <div
                class="button"
                @click="$store.commit('isShowChatStatus', true)"
              >
                了解详情
              </div>
            </div>
          </div>
          <div class="item">
            <div class="img_cont">
              <img src="../../assets/saas5.png" alt="" />
            </div>
            <div class="item_cont_container">
              <div class="item_title">专科随访系统</div>
              <div class="item_text">
                <div class="item_text_small_p">
                  <span class="sub_text_small_title">医护专科随访：</span
                  >结合脱敏治疗制定脱敏方案，患者治疗时，系统自动推荐本次治疗的所有相关信息。减少患者购药时间，智能提醒，降低患者脱落率，提高脱敏治疗效果。
                </div>
                <div class="item_text_small_p">
                  <span class="sub_text_small_title">AI专科随访：</span
                  >检测患者居家治疗记录，提供患者治疗方案，医护人员可对患者脱敏治疗进行监控，及时发现并处理患者异常情况，同时调整患者的治疗方案。
                </div>
              </div>
              <div
                class="button"
                @click="$store.commit('isShowChatStatus', true)"
              >
                了解详情
              </div>
            </div>
          </div>
          <div class="item">
            <div class="img_cont flex aic jcc">
              <div class="big_circle" id="health_xuan">
                <div class="big_dashed_circle"></div>
                <div class="animation animation1" style="top: 4%; left: 44%">
                  <div class="health_item">传递<br />信息</div>
                </div>
                <div class="animation animation2" style="top: 44%; left: 83%">
                  <div class="health_item">提高<br />意识</div>
                </div>
                <div class="animation animation3" style="top: 83%; left: 44%">
                  <div class="health_item">医患<br />沟通</div>
                </div>
                <div class="animation animation4" style="top: 44%; left: 4%">
                  <div class="health_item">健康<br />评估</div>
                </div>
                <div
                  class="animation animation1 jb_blue_ball"
                  style="top: 13%; left: 14%"
                ></div>
                <div
                  class="animation animation2 jb_blue_ball"
                  style="top: 12%; left: 83%"
                ></div>
                <div
                  class="animation animation3 jb_blue_ball"
                  style="top: 85%; left: 84%"
                ></div>
                <div
                  class="animation animation4 jb_blue_ball"
                  style="top: 83%; left: 12%"
                ></div>
                <div class="light_blue_circle">
                  <div class="translucent_blue_circle">
                    <img
                      src="../../assets/health_xuan_bg.png"
                      class="health_xuan_bg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="item_cont_container">
              <div class="item_title">健康宣教系统</div>
              <div class="item_text">
                向目标患者传递过敏的相关信息，提高患者对过敏的认识，规范诊疗流程，推动过敏诊疗更高效地进行，促进医患沟通，从而提高医疗质量。同时，患者可以通过健康宣教的内容进行健康风险评估，了解自己的健康状况。
              </div>
              <div
                class="button"
                @click="$store.commit('isShowChatStatus', true)"
              >
                了解详情
              </div>
            </div>
          </div>
          <div class="item">
            <div class="img_cont">
              <img src="../../assets/saas6.png" alt="" />
            </div>
            <div class="item_cont_container">
              <div class="item_title">科研数据管理系统</div>
              <div class="item_text">
                快速获取所需患者信息（用药、评估、检测等数据），同时进行患者隐私保护，便于科研进行；医生通过真实数据展示研究成果，提高科研数据的可管理性、可访问性和安全性，促进科研工作的协同和科学发现的推进。
              </div>
              <div
                class="button"
                @click="$store.commit('isShowChatStatus', true)"
              >
                了解详情
              </div>
            </div>
          </div>
        </div>
        <!-- 产品优势 -->
        <div class="product_advantage">
          <img
            class="bg_top_right_img"
            src="../../assets/right_top_pointer.png"
            alt=""
          />
          <img
            class="bg_bottom_left_img"
            src="../../assets/left_bottom_pointer.png"
            alt=""
          />
          <div class="flex jcc aic">
            <div class="cont_title">
              <div class="cont_title_txt">
                <div>产品优势</div>
              </div>
              <div class="cont_title_tip">
                <img
                  src="../../assets/blue_point.png"
                  alt=""
                  class="blue_pointer"
                />
              </div>
            </div>
          </div>
          <div class="advantage_list">
            <div class="advantage_list_item">
              <img src="../../assets/advantage_list1.png" alt="" />
              <div class="item_cont">
                <div class="item_title">简化工作流程</div>
                <div class="item_content">
                  专科数字化、优化诊疗流程，提高诊疗效率，加强科室管理。
                </div>
              </div>
            </div>
            <div class="advantage_list_item">
              <img src="../../assets/advantage_list2.png" alt="" />
              <div class="item_cont">
                <div class="item_title">优化患者管理</div>
                <div class="item_content">
                  智能化患者的院外居家管理监测，提高病人对科室的归属感和依从性。
                </div>
              </div>
            </div>
            <div class="advantage_list_item">
              <img src="../../assets/advantage_list3.png" alt="" />
              <div class="item_cont">
                <div class="item_title">增加科室收入</div>
                <div class="item_content">
                  消除药品差价、全方位病源管理，及时满足患者的复诊需求。
                </div>
              </div>
            </div>
            <div class="advantage_list_item">
              <img src="../../assets/advantage_list4.png" alt="" />
              <div class="item_cont">
                <div class="item_title">提高设备利用率</div>
                <div class="item_content">
                  跨科室设备协同预约系统，实现同步预约，数据回传，提高协调效率和设备利用率。
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 社会价值 -->
        <div class="patient_value">
          <div class="flex jcc aic">
            <div class="cont_title">
              <div class="cont_title_txt">
                <div>社会价值</div>
              </div>
              <div class="cont_title_tip">
                <img
                  src="../../assets/blue_point.png"
                  alt=""
                  class="blue_pointer"
                />
              </div>
            </div>
          </div>
          <div class="patient_value_cont">
            <div class="patient_value_left">
              <img
                v-if="society == 1"
                class="patient_value_left_img"
                src="../../assets/society_value1.png"
                alt=""
              />
              <img
                v-if="society == 2"
                class="patient_value_left_img"
                src="../../assets/society_value2.png"
                alt=""
              />
              <img
                v-if="society == 3"
                class="patient_value_left_img"
                src="../../assets/society_value3.png"
                alt=""
              />
              <div class="patient_value_left_cont">
                <div class="patient_value_left_title">
                  {{
                    society == 1
                      ? "数据收集与研究支持"
                      : society == 2
                      ? "解决社会问题"
                      : society == 3
                      ? "填补区域空白"
                      : ""
                  }}
                </div>
                <div class="patient_value_left_list">
                  <div class="patient_value_left_item">
                    <div class="patient_value_left_item_diamond">
                      <div></div>
                      <div></div>
                    </div>
                    <div class="patient_value_left_item_desc">
                      {{
                        society == 1
                          ? "收集、分析过敏相关数据，为过敏研究提供资源；"
                          : society == 2
                          ? "以数字化手段，集中解决社会中日益增长的过敏困扰问题。"
                          : society == 3
                          ? "填补区域过敏中心建设不足的问题，起到先驱示范作 用；"
                          : ""
                      }}
                    </div>
                  </div>
                  <div class="patient_value_left_item">
                    <div
                      class="patient_value_left_item_diamond"
                      v-if="society !== 2"
                    >
                      <div></div>
                      <div></div>
                    </div>
                    <div class="patient_value_left_item_desc">
                      {{
                        society == 1
                          ? "促进对过敏机制、预防和治疗方法的深入研究，进一步推动过敏领域的发展和创新。"
                          : society == 3
                          ? "改善过敏患者的生活质量，提升社会对过敏问题的认 识和关注度，推动科学研究和医疗技术的发展。"
                          : ""
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="patient_value_right">
              <div
                :class="[
                  'patient_value_right_item',
                  society == 1 ? 'patient_value_right_item_active' : '',
                ]"
                @click="society = 1"
              >
                数据收集与研究支持
              </div>
              <div
                :class="[
                  'patient_value_right_item',
                  society == 2 ? 'patient_value_right_item_active' : '',
                ]"
                @click="society = 2"
              >
                解决社会问题
              </div>
              <div
                :class="[
                  'patient_value_right_item',
                  society == 3 ? 'patient_value_right_item_active' : '',
                ]"
                @click="society = 3"
              >
                填补区域空白
              </div>
            </div>
          </div>
          <div class="patient_value_cont" v-if="isMobile">
            <div class="patient_value_left">
              <img
                class="patient_value_left_img"
                src="../../assets/society_value2.png"
                alt=""
              />
              <div class="patient_value_left_cont">
                <div class="patient_value_left_title">解决社会问题</div>
                <div class="patient_value_left_list">
                  <div class="patient_value_left_item">
                    <div class="patient_value_left_item_diamond">
                      <div></div>
                      <div></div>
                    </div>
                    <div class="patient_value_left_item_desc">
                      以数字化手段，集中解决社会中日益增长的过敏困扰问题。
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="patient_value_cont" v-if="isMobile">
            <div class="patient_value_left">
              <img
                class="patient_value_left_img"
                src="../../assets/society_value3.png"
                alt=""
              />
              <div class="patient_value_left_cont">
                <div class="patient_value_left_title">填补区域空白</div>
                <div class="patient_value_left_list">
                  <div class="patient_value_left_item">
                    <div class="patient_value_left_item_diamond">
                      <div></div>
                      <div></div>
                    </div>
                    <div class="patient_value_left_item_desc">
                      填补区域过敏中心建设不足的问题，起到先驱示范作
                    </div>
                  </div>
                  <div class="patient_value_left_item">
                    <div class="patient_value_left_item_diamond">
                      <div></div>
                      <div></div>
                    </div>
                    <div class="patient_value_left_item_desc">
                      改善过敏患者的生活质量，提升社会对过敏问题的认识和关注度，推动科学研究和医疗技术的发展。
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Main>
</template>

<script></script>
<script>
import computed from "./src/computed"
import watch from "./src/watch"
import Main from "../../components/Main"
import anime from "animejs"

export default {
  name: "saas",
  data () {
    return {
      society: 1,
      isMobile: false,
    }
  },
  components: {
    Main,
  },
  watch,
  methods: {
    showAnime (typeArr) {
      let arr = []
      let _this = this
      typeArr.forEach((el) => {
        if (!document.querySelector(`#${el}`)) return
        let obj = {
          el: `#${el}`,
          loop: false, //是否循环使用 true/false 设置false后执行执行一次后会销毁
          type: "all", //触发方式 支持up-down down-up all三种方式
          func: function () {
            anime({
              targets: `#${el}`,
              scale: [0, 1],
              opacity: [0, 1],
              delay: 0,
            })
            anime({
              targets: ".bg_img",
              scale: [0, 1],
              opacity: [0, 1],
              delay: 150,
            })
            document
              .querySelectorAll(`#${el} .animation`)
              .forEach((item, index) => {
                anime({
                  targets: `#${el} .animation` + (index + 1),
                  scale: [0, 1],
                  opacity: [0, 1],
                  delay: (index + 2) * 150,
                })
              })
          },
        }
        arr.push(obj)
      })

      new MyScroll({
        event: arr,
      })
    },
    isMobi () {
      if (window.innerWidth < 769) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    },
  },
  computed,
  mounted () {
    window.addEventListener("resize", this.isMobi)
    this.isMobi()
    this.$nextTick(() => {
      this.showAnime(["eva_system", "health_xuan"])
    })
  },
};
</script>

<style lang="scss" scoped>
@import "./css/index.scss";
@import "./css/saas.scss";
</style>
