<template>
  <Main>
    <div class="news" slot="main_content">
      <img
        v-if="!isMobile"
        class="news_img"
        src="../../assets/news_bg.jpg"
        alt=""
      />
      <img
        v-else
        class="news_img"
        src="../../assets/phone/news_bg.jpg"
        alt=""
      />
      <!-- 新闻资讯 -->
      <div class="news_cont">
        <div class="news_list">
          <div class="news_item" v-for="(item, index) in newsList" :key="index">
            <div class="img_cont">
              <img class="news_item_img" :src="item.thumb" alt="" />
            </div>
            <div class="flexcol jcsb">
              <div>
                <div class="news_item_title">{{ item.title }}</div>
                <div class="news_item_cont" v-html="item.subtitle"></div>
              </div>
              <div class="flex jcsb btn_time_ctn">
                <div
                  class="button"
                  @click="
                    $router.push({
                      path: '/news/detail',
                      query: { newsid: item.newsid },
                    })
                  "
                >
                  查看详情
                </div>
                <div class="news_item_time">{{ item.time }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- 分页 -->
        <div class="news_paginate">
          <el-pagination
            @current-change="currentChange"
            :current-page="currentPage"
            :page-size="9"
            :total="total"
            layout="prev, pager, next"
            prev-text="上一页"
            next-text="下一页"
            background
          ></el-pagination>
        </div>
      </div>
    </div>
  </Main>
</template>

<script>
import computed from "./src/computed";
import methods from "./src/methods";
import watch from "./src/watch";
import Main from "../../components/Main";

export default {
  name: "news",
  data() {
    return {
      filterSearch: {
        page: 1,
        limit: 9,
      },
      newsList: [],
      total: 0,
      currentPage: 1,
      isMobile: false,
    };
  },
  components: {
    Main,
  },
  watch,
  methods,
  computed,
  created() {
    window.addEventListener("resize", this.isMobi);
    this.isMobi();
    this.getData(this.filterSearch);
  },
};
</script>

<style lang="scss" scoped>
@import "./css/index.scss";
</style>
