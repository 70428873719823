/**
 * 常规方法定义
 * 1、不能用()=>{}  箭头函数定义
 * 2、大量计算方法建议放computed
 */
import { post } from "../../../http/http";

export default {
  getData(data) {
    post(this.$api.newsPage.getData, data)
      .then((res) => {
        if (res.code == 200) {
          this.newsList = res.data.lists;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      })
      .catch((err) => {
        this.$message.error(err.msg);
      });
  },
  // 当前页数发生改变
  currentChange(val) {
    this.filterSearch.page = val;
    this.currentPage = val;
    this.getData(this.filterSearch);
  },
  isMobi() {
    if (window.innerWidth < 769) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
};
