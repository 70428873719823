<template>
  <Main>
    <div class="apparatus" slot="main_content">
      <img
        v-if="!isMobile"
        class="product_img"
        src="../../assets/apparatus.jpg"
        alt=""
      />
      <img
        v-else
        class="product_img"
        src="../../assets/phone/apparatus.jpg"
        alt=""
      />

      <div class="empty">
        <img src="../../assets/empty.png" alt="" />
        <div>加急建设中...</div>
      </div>
    </div>
  </Main>
</template>

<script>
import computed from "./src/computed"
import watch from "./src/watch"
import Main from "../../components/Main"

export default {
  name: "apparatus",
  data () {
    return {
      isMobile: false
    }
  },
  components: {
    Main,
  },
  watch,
  methods: {
    isMobi () {
      if (window.innerWidth < 769) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    }
  },
  computed,
  created () {
    window.addEventListener("resize", this.isMobi)
    this.isMobi()
  },
};
</script>

<style lang="scss" scoped>
@import "./css/index.scss";
@import "./css/apparatus.scss";
</style>
