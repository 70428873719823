var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat"},[_c('el-dialog',{staticClass:"vertical-center-modal",attrs:{"width":_vm.isMobile ? '90%' : '50%',"title":"小敏正在为您服务","visible":_vm.isShowChat,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.isShowChat=$event},"close":function($event){return _vm.$emit('close')}}},[_c('div',{ref:"chat_list",staticClass:"chat_list"},_vm._l((_vm.arrList),function(item,index){return _c('div',{key:index,class:[
          'chat_item',
          item.position == 'left'
            ? 'chat_item_left'
            : item.position == 'right'
            ? 'chat_item_right'
            : '',
        ]},[(item.position == 'left')?_c('img',{staticClass:"chat_item_avatar",attrs:{"src":require("../assets/chat.png"),"alt":""}}):_vm._e(),_c('div',{staticClass:"chat_item_cont"},[_c('div',{class:[
              'chat_item_title',
              item.position == 'left'
                ? 'chat_item_title_left'
                : item.position == 'right'
                ? 'chat_item_title_right'
                : '',
            ]},[_vm._v(" "+_vm._s(item.realname)+" "+_vm._s(item.time)+" ")]),_c('div',{class:[
              'chat_item_txt',
              item.position == 'left'
                ? 'chat_item_left'
                : item.position == 'right'
                ? 'chat_item_right'
                : '',
            ]},[_c('div',{class:[
                'chat_item_text',
                item.position == 'left'
                  ? 'chat_item_text_left'
                  : item.position == 'right'
                  ? 'chat_item_text_right'
                  : '',
              ]},[_vm._v(" "+_vm._s(item.content)+" ")])])])])}),0),_c('div',{staticClass:"chat_edit"},[_c('el-input',{attrs:{"type":"textarea","placeholder":"请在此输入..."},model:{value:(_vm.ins.content),callback:function ($$v) {_vm.$set(_vm.ins, "content", $$v)},expression:"ins.content"}}),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v("发 送")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }