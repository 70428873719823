import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import api from "./http/api"
import "lib-flexible"
import BaiduMap from "vue-baidu-map"
import Vant from "vant"
import "vant/lib/index.css"
import * as echarts from "echarts"
import anime from "animejs"
import rem from "./libs/rem"

Vue.use(Vant)
Vue.use(BaiduMap, {
  ak: "MQdvsCWc5F6fEl93vkjEst4GOkyrPXfp",
})
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.prototype.$echarts = echarts

router.beforeEach(async (to, form, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = "数智医济"
  }
  next()
})

new Vue({
  router,
  store,
  rem,
  render: (h) => h(App),
}).$mount("#app")
