/**
 * 常规方法定义
 * 1、不能用()=>{}  箭头函数定义
 * 2、大量计算方法建议放computed
 */
import { post } from '../../../http/http'

export default {
    async getData(newsid) {
        post(this.$api.newsPage.getDetail, { newsid }).then(res => {
            if (res.code == 200) {
                var item = res.data;
                item.content = item.content.replace(/\<img/g, '<img style="max-width:100%;height:auto;"');
                this.item = item
            } else {
                this.$message.error(res.msg)
            }
        }).catch(err => {
            this.$message.error(err.msg)
        })
    }
}